import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useChatMessagesQuery = (sessionId?: number) => {
    return useQuery({
        queryKey: ["chatMessages", sessionId],
        queryFn: async () => {
            if (!sessionId) return null;
            return await sdk.functional.bot.sessions.messages.getMessages(getApiConnection(), sessionId);
        },
    });
};
