import type { IConnection } from "@nestia/fetcher";
import { getUser } from "./oidc.ts";

export interface AuthHeaders extends Record<string, string> {
    Authorization: string;
}

export const getApiConnection = (): IConnection<AuthHeaders> => {
    return {
        host: import.meta.env.VITE_API_BASE,
        headers: getAuthHeaders(),
    };
};

export const getAuthHeaders = (): AuthHeaders => {
    return {
        Authorization: getUser()?.access_token ? `Bearer ${getUser()?.access_token}` : "",
    };
};
