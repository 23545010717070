import { FC, PropsWithChildren } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

interface RequiresAuthProps extends PropsWithChildren {}

// todo: props to decide what to do, message, hide content, redirect. makes it more flexible to hide content etc
export const RequiresAuth: FC<RequiresAuthProps> = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth();
    const navigate = useNavigate();

    if (isLoading) return null;

    if (!isAuthenticated) {
        // Redirect to login automatically if user is not logged in.
        // replace = Dont put this in history, replace.
        navigate("/", { replace: true });
        return null;
    }

    return children;
};
