import { useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useUploadAssetMutation } from "../../../../../lib/mutations/useUploadAssetMutation.ts";

export const useUploads = (caseId: number, onClose: () => void) => {
    const { mutateAsync: uploadAsset, isLoading: isUploading } = useUploadAssetMutation(caseId);

    const form = useForm<{ files: File[]; key: string | null }>({
        defaultValues: {
            key: null, // todo: this makes no sense, key should be PER file, not for ALL files
            files: [],
        },
    });

    const uploadedToast = useToast({
        title: "File(s) uploaded",
        status: "success",
        duration: 3000,
        isClosable: true,
        colorScheme: "purple",
    });

    const onSubmit = form.handleSubmit(async (data) => {
        if (!data.files.length) return;
        const promises = data.files.map((file) => uploadAsset({ file, key: null }));
        await Promise.all(promises);
        uploadedToast();
        form.reset();
        onClose();
    });

    const mergeFiles = (newFiles: File[]) => {
        const files = form.watch("files").slice();
        files.push(...newFiles);
        form.setValue("files", files);
    };

    const removeFile = (index: number) => {
        console.log("remove file", index);
        const files = form.watch("files").slice();
        files.splice(index, 1);
        form.setValue("files", files);
    };

    return {
        form,
        onSubmit,
        mergeFiles,
        removeFile,
        isUploading,
    };
};
