import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useAttemptsQuery = (caseId: number) => {
    return useQuery({
        queryKey: ["attempts", caseId],
        queryFn: async () => {
            const data = await sdk.functional.$case.attempts.getAttempts(getApiConnection(), caseId);

            return {
                description: data.find((i) => i.data_type === "description")?.success === null,
                employees: data.find((i) => i.data_type === "employees")?.success === null,
            };
        },
    });
};
