import { extendTheme } from "@chakra-ui/react";
import { avatarTheme } from "./theme/avatar.ts";

export const theme = extendTheme({
    styles: {
        global: {
            body: {
                minH: "100dvh",
            },
            ".chakra-avatar:has(.chakra-avatar__img)": {
                // Since we override the avatar colors, images may end up with a slight bg leaking through
                // This ensures the bg is always transparent when an image is present
                bg: "none",
            },
            ul: {
                listStylePosition: "inside",
            },
        },
    },
    fonts: {
        heading: `'DM Sans', sans-serif`,
        body: `'DM Sans', sans-serif`,
    },
    colors: {
        white: "#ffffff",
        black: {
            500: "#242930",
            800: "#191D22",
        },
        gray: {
            100: "#F6F9FF",
            200: "#EEF2FB",
            300: "#E3E9F6",
            400: "#D1D8E9",
            500: "#929DBB",
            600: "#6A7697",
            800: "#444D67",
        },
        purple: {
            100: "#EFEDFD",
            200: "#DFDAFB",
            300: "#BEB3FF",
            400: "#9685FF",
            500: "#5E47E9",
            600: "#4D3ABF", // generated
            700: "#3C2E94", // generated
            "opacity.10": "rgba(94, 71, 233, 0.1)",
        },
        linkedinBlue: {
            // note: chakraui has a "linkedin" scheme color but it's not correct.
            500: "#0A66C2",
            600: "#084C92",
        },
        error: {
            // Note: asked if these can be properly added to the design system
            100: "#FFECF2",
            400: "#D42C63",
            500: "#B61B4F",
        },
        warn: {
            500: "#ffc24d",
        },
    },
    shadows: {
        // "2xl": "0 25px 50px -10px var(--chakra-colors-gray-200)",
        elevation4: "0 4px 20px 0 rgba(134, 136, 184, 0.2)", // #8688b8 20% opacity
        dark: "0 4px 40px 0 rgba(14,14,22,0.5)",
    },
    components: {
        Avatar: avatarTheme,
        Popover: {
            baseStyle: {
                // https://v2.chakra-ui.com/docs/components/popover/theming
                // A: content
                // B: header
                // C: body
                // D: footer
                // E: popper
                // F: arrow
                // G: closeButton

                arrow: {
                    display: "none",
                },

                content: {
                    marginTop: 1,
                    borderRadius: "3xl",
                    border: "none",
                    shadow: "2xl", // may not match design
                    padding: 3,
                },

                header: {
                    border: "none",
                },
            },
        },
        Container: {
            baseStyle: {
                maxW: "container.xl",
            },
        },
        Button: {
            baseStyle: {
                cursor: "pointer",
                borderRadius: "full",
                fontWeight: "normal",
            },
            sizes: {
                md: {
                    fontSize: "sm",
                },
            },
            variants: {
                menuButton: {
                    rounded: "2xl",
                    px: 4,
                    justifyContent: "flex-start",
                    bg: "none",
                    color: "gray.500",
                    _hover: {
                        bg: "gray.800",
                    },
                    _active: {
                        bg: "gray.800",
                        fontWeight: "bold",
                        color: "white",
                    },
                },
                dropdown: {
                    bg: "gray.300",
                    _hover: {
                        bg: "gray.400",
                    },
                    _active: {
                        bg: "gray.400",
                    },
                },
                chipButton: {
                    justifyContent: "flex-start",
                    bg: "purple.100",
                    color: "purple.500",
                    outline: "1px solid",
                    outlineColor: "purple.400",
                    _hover: {
                        bg: "gray.50",
                    },
                    _active: {
                        bg: "gray.300",
                    },
                },
                ghost: {
                    bg: "none",
                    color: "purple.500",
                    _hover: {
                        bg: "gray.50",
                    },
                    _active: {
                        bg: "gray.100",
                    },
                },
            },
        },
        Card: {
            baseStyle: {
                // https://chakra-ui.com/docs/components/card/theming#anatomy
                container: {
                    bg: "white",
                    shadow: "elevation4",
                    borderRadius: "3xl",
                    p: 6,
                },
                header: { p: 0, pb: 6 },
                body: { p: 0 },
                // footer: { p: 8, pt: 0 },
            },
            variants: {
                subtle: {
                    container: {
                        bg: "gray.200",
                        shadow: "none",
                    },
                },
                flat: {
                    container: {
                        bg: "white",
                        borderRadius: "3xl",
                        border: "1px solid #D1D8E9", // gray.400
                        shadow: "none",
                        p: 6,
                    },
                },
            },
        },

        Tag: {
            defaultProps: {
                size: "lg",
            },
            baseStyle: {
                container: {
                    gap: 2,
                    borderRadius: "full",
                    // bg: "gray.200",
                },
            },
            sizes: {
                sm: {
                    container: {
                        borderRadius: "md",
                    },
                },
                lg: {
                    container: {
                        px: 4,
                    },
                    label: {
                        fontSize: "sm",
                    },
                },
            },
            variants: {
                loader: {
                    container: { bg: "purple.opacity.10" },
                    label: { color: "purple.500" },
                },
                error: {
                    container: { bg: "error.100" },
                    label: { color: "error.500" },
                },
                filter: {
                    container: { bg: "purple.500" },
                    label: { color: "white", fontWeight: "bold" },
                },
            },
        },
        Heading: {
            baseStyle: {
                fontWeight: "700",
                color: "black.500",
            },
            variants: {
                h1: {
                    fontSize: "3xl",
                },
                h2: {
                    fontSize: "2xl",
                },
                h3: {
                    fontSize: "xl",
                },
            },
        },
        Text: {
            baseStyle: {
                color: "gray.800",
            },
            variants: {
                labelCaps: {
                    textTransform: "uppercase",
                    fontSize: "xs",
                    color: "gray.600",
                    fontWeight: "bold",
                },
                title: {
                    fontSize: "md",
                    fontWeight: "bold",
                },
                subheader: {
                    fontSize: "lg",
                    color: "gray.600",
                },
                body: {
                    fontSize: "md",
                },
                body2: {
                    fontSize: "sm",
                },
                bodyBold: {
                    fontSize: "md",
                    fontWeight: "bold",
                    color: "black.500",
                },
                label: {
                    fontSize: "xs",
                    fontWeight: "normal",
                },
                labelBold: {
                    fontSize: "xs",
                    fontWeight: "bold",
                },
                menuActive: {
                    fontSize: "sm",
                    fontWeight: "bold",
                    color: "white",
                },
            },
        },
        StackDivider: {
            baseStyle: {
                borderColor: "gray.50",
            },
        },
        Input: {
            variants: {
                ghost: {
                    field: {
                        bg: "gray.100",
                        borderRadius: "full",
                        color: "gray.500",
                        _placeholder: { color: "gray.400" },
                    },
                    element: {
                        color: "gray.500",
                    },
                },
                gray: {
                    field: {
                        borderRadius: "full",
                        bg: "gray.200",
                        color: "gray.500",
                        _placeholder: { color: "gray.500" },
                    },
                },
                solid: {
                    field: {
                        bg: "gray.300",
                        borderRadius: "full",
                        color: "gray.600",
                        _placeholder: { color: "gray.500" },
                    },
                    element: {
                        color: "gray.500",
                    },
                },
            },
        },
        Textarea: {
            variants: {
                solid: {
                    bg: "gray.300",
                    borderRadius: "2xl",
                    color: "gray.600",
                    _placeholder: { color: "gray.500" },
                },
            },
        },
        Modal: {
            baseStyle: {
                footer: {
                    bg: "gray.50",
                    borderRadius: "inherit",
                },
            },
        },
        Tooltip: {
            defaultProps: {
                hasArrow: true,
            },
            baseStyle: {
                borderRadius: "md",
                p: 3,
            },
        },
        Menu: {
            variants: {
                sideNav: {
                    button: {
                        p: 2,
                        pr: 4,
                        bg: "purple.300",
                        borderRadius: "2xl",
                        h: "unset",
                        _hover: { bg: "purple.200" },
                        _active: { bg: "purple.200" },
                    },
                    list: {
                        bg: "black.500",
                        border: "none",
                        shadow: "dark",
                        borderRadius: "3xl",
                        minW: "320px",
                        p: 4,
                    },
                    item: {
                        bg: "none",
                        color: "white",
                        borderRadius: "2xl",
                        p: 2,
                        display: "flex",
                        flexDirection: "row",
                        gap: 4,
                        fontSize: "sm",
                        fontWeight: "bold",
                        _hover: { bg: "gray.800" },
                        mb: 2,
                        _last: { mb: 0 },
                    },
                    divider: {
                        color: "gray.800",
                    },
                    groupTitle: {
                        mt: 0,
                        mx: 0,
                        mb: 4,
                        color: "white",
                    },
                },
                userMenu: {
                    button: {
                        p: 2,
                        bg: "black.800",
                        borderRadius: "2xl",
                    },
                },
            },
        },
    },
});
