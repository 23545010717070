import { FC } from "react";
import { RouterProvider } from "react-router-dom";
import { useTitle } from "react-use";
import { router } from "./lib/app/router.tsx";

import "@fontsource/dm-sans";
import { useSmartlook } from "./lib/app/useSmartlook.ts";

// import "@fontsource-variable/dm-sans";

interface AppProps {}
export const App: FC<AppProps> = ({}) => {
    useTitle("Maven");
    useSmartlook();
    return <RouterProvider router={router} />;
};
