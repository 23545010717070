import { useMutation } from "@tanstack/react-query";
import sdk from "../../sdk";
import { UploadAssetDto } from "../../sdk/structures/UploadAssetDto.ts";
import { getApiConnection } from "../app/api.ts";
import { queryClient } from "../app/queryClient.ts";

export const useUploadAssetMutation = (caseId: number) => {
    return useMutation(
        ["uploadAsset", caseId],
        // todo: file uploads in nestia?
        async ({ file, key }: { file: File; key: UploadAssetDto["key"] }) => {
            await sdk.functional.asset.$case.assets.uploadAsset(getApiConnection(), caseId, {
                file,
                key,
            });

            // await axios.post(
            //     `/asset/case/${caseId}/assets`,
            //     { file, key },
            //     {
            //         headers: { "Content-Type": "multipart/form-data" },
            //     },
            // );

            // Incorrectly deals with FormData (tries to stringify the file...)
            // See https://github.com/OpenAPITools/openapi-generator/issues/5112
            // await api.assetControllerUploadAsset(caseId, file);
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(["assets", caseId]);
            },
        },
    );
};
