import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useMemoQuery = (caseId: number, topic: string) => {
    return useQuery({
        queryKey: ["memo", caseId, topic],
        queryFn: async () => {
            return await sdk.functional.memo.cases.memos.getMemo(getApiConnection(), caseId, topic);
        },
    });
};
