import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FilterPopover } from "../../ui/filterPopover/FilterPopover.tsx";

const options = [
    { value: "all", min: undefined, max: undefined, label: "All ages", tag: null },
    { value: "1-3", min: 1, max: 3, label: "1-3 years old", tag: "1-3" },
    { value: "<10", min: 0, max: 10, label: "younger than 10 years", tag: "< 10" },
    { value: ">10", min: 10, max: 100, label: "older than 10 years", tag: "10+" },
];

interface AgeFilterProps {
    minAge?: number;
    maxAge?: number;
    onChange: (minAge?: number, maxAge?: number) => void;
}

export const AgeFilter: FC<AgeFilterProps> = ({ minAge, maxAge, onChange }) => {
    const { watch, setValue } = useForm<{ minAge?: number; maxAge?: number }>({
        defaultValues: { minAge, maxAge },
    });

    // Set form values when incoming values change
    useEffect(() => {
        setValue("minAge", minAge);
        setValue("maxAge", maxAge);
    }, [minAge, maxAge, setValue]);

    const cancel = () => {
        setValue("minAge", minAge);
        setValue("maxAge", maxAge);
        apply();
    };

    const clear = () => {
        setValue("minAge", undefined);
        setValue("maxAge", undefined);
        apply();
    };

    const apply = () => {
        onChange(watch("minAge"), watch("maxAge"));
    };

    const ageToOption = (minAge?: number, maxAge?: number) =>
        options.find((option) => option.min === minAge && option.max === maxAge);

    const activeOption = ageToOption(minAge, maxAge);

    return (
        <FilterPopover
            buttonLabel={"Age"}
            tagLabel={activeOption?.tag}
            title={"Age"}
            onClear={clear}
            onCancel={cancel}
            onApply={apply}
        >
            <RadioGroup
                colorScheme={"purple"}
                value={ageToOption(watch("minAge"), watch("maxAge"))?.value}
                onChange={(value) => {
                    const option = options.find((option) => option.value === value);
                    setValue("minAge", option?.min);
                    setValue("maxAge", option?.max);
                }}
            >
                <Stack>
                    {options.map((option) => (
                        <Radio key={option.value} value={option.value}>
                            {option.label}
                        </Radio>
                    ))}
                </Stack>
            </RadioGroup>
        </FilterPopover>
    );
};
