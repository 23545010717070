/**
 * @packageDocumentation
 * @module api.functional.asset.$case.assets
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Format } from "typia/lib/tags/Format";

import type { Prisma } from "../../../../structures/Prisma";
import type { UploadAssetDto } from "../../../../structures/UploadAssetDto";

export * as url from "./url";

/**
 * @controller AssetController.getAssets
 * @path GET /asset/case/:caseId/assets
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getAssets(connection: IConnection, caseId: number): Promise<getAssets.Output> {
    return PlainFetcher.fetch(connection, {
        ...getAssets.METADATA,
        template: getAssets.METADATA.path,
        path: getAssets.path(caseId),
    });
}
export namespace getAssets {
    export type Output = {
        id: number;
        filename: string;
        path: string;
        mime: string;
        key: null | string;
        case_id: number;
        meta: Prisma.JsonValue;
        processing_error: null | string;
        processed_at: null | (string & Format<"date-time">);
        created_at: string & Format<"date-time">;
        index_pending: boolean;
        parent_id: null | number;
        index_error: null | string;
    }[];

    export const METADATA = {
        method: "GET",
        path: "/asset/case/:caseId/assets",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number) => `/asset/case/${encodeURIComponent(caseId ?? "null")}/assets`;
}

/**
 * @controller AssetController.getAsset
 * @path GET /asset/case/:caseId/assets/:assetId
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getAsset(connection: IConnection, caseId: number, assetId: number): Promise<getAsset.Output> {
    return PlainFetcher.fetch(connection, {
        ...getAsset.METADATA,
        template: getAsset.METADATA.path,
        path: getAsset.path(caseId, assetId),
    });
}
export namespace getAsset {
    export type Output = {
        id: number;
        filename: string;
        path: string;
        mime: string;
        key: null | string;
        case_id: number;
        meta: Prisma.JsonValue;
        processing_error: null | string;
        processed_at: null | (string & Format<"date-time">);
        created_at: string & Format<"date-time">;
        index_pending: boolean;
        parent_id: null | number;
        index_error: null | string;
    };

    export const METADATA = {
        method: "GET",
        path: "/asset/case/:caseId/assets/:assetId",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number, assetId: number) =>
        `/asset/case/${encodeURIComponent(caseId ?? "null")}/assets/${encodeURIComponent(assetId ?? "null")}`;
}

/**
 * @controller AssetController.uploadAsset
 * @path POST /asset/case/:caseId/assets
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function uploadAsset(
    connection: IConnection,
    caseId: number,
    data: uploadAsset.Input,
): Promise<uploadAsset.Output> {
    return PlainFetcher.fetch(
        connection,
        {
            ...uploadAsset.METADATA,
            template: uploadAsset.METADATA.path,
            path: uploadAsset.path(caseId),
        },
        data,
    );
}
export namespace uploadAsset {
    export type Input = UploadAssetDto;
    export type Output = {
        id: number;
        filename: string;
        path: string;
        mime: string;
        key: null | string;
        case_id: number;
        meta: Prisma.JsonValue;
        processing_error: null | string;
        processed_at: null | (string & Format<"date-time">);
        created_at: string & Format<"date-time">;
        index_pending: boolean;
        parent_id: null | number;
        index_error: null | string;
    };

    export const METADATA = {
        method: "POST",
        path: "/asset/case/:caseId/assets",
        request: {
            type: "multipart/form-data",
            encrypted: false,
        },
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number) => `/asset/case/${encodeURIComponent(caseId ?? "null")}/assets`;
}

/**
 * @controller AssetController.deleteAsset
 * @path DELETE /asset/case/:caseId/assets/:assetId
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function deleteAsset(connection: IConnection, caseId: number, assetId: number): Promise<void> {
    return PlainFetcher.fetch(connection, {
        ...deleteAsset.METADATA,
        template: deleteAsset.METADATA.path,
        path: deleteAsset.path(caseId, assetId),
    });
}
export namespace deleteAsset {
    export const METADATA = {
        method: "DELETE",
        path: "/asset/case/:caseId/assets/:assetId",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number, assetId: number) =>
        `/asset/case/${encodeURIComponent(caseId ?? "null")}/assets/${encodeURIComponent(assetId ?? "null")}`;
}
