import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useAssetsQuery = (caseId: number) => {
    return useQuery({
        queryKey: ["assets", caseId],
        queryFn: async () => {
            return await sdk.functional.asset.$case.assets.getAssets(getApiConnection(), caseId);
        },
        refetchInterval: (assets) => {
            // Keep refetching while there are any assets with index_pending, beats having to implement a socket or SSE
            // for such a simple use case
            if (assets?.some((a) => a.index_pending)) return 500;
            return false;
        },
    });
};
