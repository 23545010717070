import { Button, Icon } from "@chakra-ui/react";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { SourcesPopover } from "../SourcePopover/SourcePopover.tsx";
import { Sources } from "../Sources/Sources.tsx";

interface SourcesProps {
    chunkId: string;
}

export const QuoteButton: FC<SourcesProps> = ({ chunkId }) => {
    return (
        <SourcesPopover heading={"Sources"} chunkIds={[chunkId]} trigger={"hover"}>
            {/* quote button */}
            <Button
                rounded="md"
                px={0}
                size={"sm"}
                aria-label={"source citation button"}
                color={"gray.600"}
                variant={"ghost"}
                _hover={{ bg: "gray.300" }}
            >
                <Icon boxSize={4} as={FontAwesomeIcon} icon={faQuoteRight} />
            </Button>
        </SourcesPopover>
    );
};
