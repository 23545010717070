import { useMutation } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";
import { queryClient } from "../app/queryClient.ts";

export const useCreateCaseWithPitchdeckMutation = () => {
    return useMutation({
        mutationKey: ["createCaseUpload"],
        mutationFn: async (data: { name: string; website?: string; pitchdeck: File }) => {
            // Prepend https:// to website if it has no protocol
            if (data.website && !data.website.startsWith("http")) {
                data.website = `https://${data.website}`;
            }

            const $case = await sdk.functional.$case.createCase(getApiConnection(), {
                name: data.name,
                website: data.website,
            });

            await sdk.functional.asset.$case.assets.uploadAsset(getApiConnection(), $case.id, {
                file: data.pitchdeck,
                key: "deck",
            });

            return $case;
        },
        onSuccess: async ($case) => {
            await queryClient.invalidateQueries(["assets", $case.id]);
        },
    });
};
