import { Stack } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";
import { BgGradient } from "../bgGradient/BgGradient.tsx";

interface BaseLayoutProps extends PropsWithChildren {}
export const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
    return (
        <Stack minH={"100dvh"} background={"gray.50"} gap={10}>
            <BgGradient />
            {children}
        </Stack>
    );
};
