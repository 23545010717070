/**
 * @packageDocumentation
 * @module api.functional.asset
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

export * as $case from "./$case";

/**
 * @controller AssetController.reindexAll
 * @path POST /asset/reindexAll
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function reindexAll(connection: IConnection): Promise<reindexAll.Output> {
    return PlainFetcher.fetch(connection, {
        ...reindexAll.METADATA,
        template: reindexAll.METADATA.path,
        path: reindexAll.path(),
    });
}
export namespace reindexAll {
    export type Output = string;

    export const METADATA = {
        method: "POST",
        path: "/asset/reindexAll",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = () => "/asset/reindexAll";
}
