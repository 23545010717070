import {
    HStack,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Radio,
    RadioGroup,
    Stack,
    Text,
} from "@chakra-ui/react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FilterPopover } from "../../ui/filterPopover/FilterPopover.tsx";

const options = [
    { min: undefined, max: undefined, label: "All sizes", value: "all" },
    { min: 1, max: 10, label: "Small (1-10)", value: "small" },
    { min: 10, max: 250, label: "Medium (10-250)", value: "medium" },
    { min: 250, max: 1000, label: "Large (250-1000)", value: "large" },
    { min: 1000, max: undefined, label: "Huge (1000+)", value: "huge" },
];

interface EmployeesFilterProps {
    minEmployees?: number;
    maxEmployees?: number;
    onChange: (minEmployees?: number, maxEmployees?: number) => void;
}

export const EmployeesFilter: FC<EmployeesFilterProps> = ({ minEmployees, maxEmployees, onChange }) => {
    const { watch, setValue } = useForm<{ minEmployees?: number; maxEmployees?: number }>({
        defaultValues: { minEmployees: minEmployees, maxEmployees: maxEmployees },
    });

    // Set form values when incoming values change
    useEffect(() => {
        setValue("minEmployees", minEmployees);
        setValue("maxEmployees", maxEmployees);
    }, [minEmployees, maxEmployees, setValue]);

    const cancel = () => {
        setValue("minEmployees", minEmployees);
        setValue("maxEmployees", maxEmployees);
        apply();
    };

    const clear = () => {
        setValue("minEmployees", undefined);
        setValue("maxEmployees", undefined);
        apply();
    };

    const apply = () => {
        onChange(watch("minEmployees"), watch("maxEmployees"));
    };

    const getTagLabel = () => {
        if (!minEmployees && !maxEmployees) return;
        if (!minEmployees && maxEmployees) return `< ${maxEmployees}`;
        if (minEmployees && !maxEmployees) return `> ${minEmployees}`;
        return `${minEmployees} - ${maxEmployees}`;
    };

    const selectedOption =
        options.find((option) => option.min === watch("minEmployees") && option.max === watch("maxEmployees"))?.value ??
        "";

    return (
        <FilterPopover
            buttonLabel={"Employees"}
            tagLabel={getTagLabel()}
            title={"Employees"}
            onClear={clear}
            onCancel={cancel}
            onApply={apply}
        >
            <RadioGroup
                colorScheme={"purple"}
                onChange={(value) => {
                    // Set filters based on provided option
                    const option = options.find((option) => option.value === value);
                    if (option) {
                        setValue("minEmployees", option.min);
                        setValue("maxEmployees", option.max);
                    }
                }}
                value={selectedOption}
            >
                <Stack>
                    {options.map((option) => (
                        <Radio key={option.label} value={option.value}>
                            {option.label}
                        </Radio>
                    ))}
                </Stack>
            </RadioGroup>

            <HStack>
                <InputGroup size="md">
                    <Input
                        placeholder={"No min"}
                        value={watch("minEmployees") ?? ""}
                        type={"number"}
                        onChange={(e) =>
                            setValue("minEmployees", e.target.value ? parseInt(e.target.value) : undefined)
                        }
                        variant={"filled"}
                        min={1}
                        max={999999}
                    />
                    <InputRightElement>
                        <IconButton
                            aria-label={"Clea"}
                            variant={"ghost"}
                            isDisabled={!watch("minEmployees")}
                            size="xs"
                            onClick={() => {
                                setValue("minEmployees", undefined);
                            }}
                            icon={<Icon as={FontAwesomeIcon} icon={faXmark} />}
                        />
                    </InputRightElement>
                </InputGroup>
                <Text>to</Text>

                <InputGroup size="md">
                    <Input
                        placeholder={"No max"}
                        value={watch("maxEmployees") ?? ""}
                        type={"number"}
                        onChange={(e) =>
                            setValue("maxEmployees", e.target.value ? parseInt(e.target.value) : undefined)
                        }
                        variant={"filled"}
                        min={1}
                        max={999999}
                    />
                    <InputRightElement>
                        <IconButton
                            aria-label={"Clear"}
                            variant={"ghost"}
                            isDisabled={!watch("maxEmployees")}
                            size="xs"
                            onClick={() => {
                                setValue("maxEmployees", undefined);
                            }}
                            icon={<Icon as={FontAwesomeIcon} icon={faXmark} />}
                        />
                    </InputRightElement>
                </InputGroup>
            </HStack>
        </FilterPopover>
    );
};
