import { createBrowserRouter } from "react-router-dom";
import { RequiresAuth } from "../../components/auth/RequiresAuth.tsx";
import { CallbackPage } from "../../pages/callbackPage/CallbackPage.tsx";
import { CasePage } from "../../pages/casePage/CasePage.tsx";
import { AssetsPage } from "../../pages/assetsPage/AssetsPage.tsx";
import { CasesPage } from "../../pages/casesPage/CasesPage.tsx";
import { LandingPage } from "../../pages/landingPage/LandingPage.tsx";
import { MaeveChatPage } from "../../pages/maeveChatPage/MaeveChatPage.tsx";
import { MemoPage } from "../../pages/memoPage/MemoPage.tsx";
import { NewCasePage } from "../../pages/newCasePage/NewCasePage.tsx";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <LandingPage />,
    },
    {
        path: "/cases",
        element: (
            <RequiresAuth>
                <CasesPage />
            </RequiresAuth>
        ),
    },
    {
        path: "/cases/new",
        element: (
            <RequiresAuth>
                <NewCasePage />
            </RequiresAuth>
        ),
    },
    {
        path: "/cases/:caseId",
        element: (
            <RequiresAuth>
                <CasePage />
            </RequiresAuth>
        ),
    },
    {
        path: "/cases/:caseId/assets",
        element: (
            <RequiresAuth>
                <AssetsPage />
            </RequiresAuth>
        ),
    },
    {
        path: "/cases/:caseId/memo",
        element: (
            <RequiresAuth>
                <MemoPage />
            </RequiresAuth>
        ),
    },
    {
        path: "/cases/:caseId/chat",
        element: (
            <RequiresAuth>
                <MaeveChatPage />
            </RequiresAuth>
        ),
    },
    {
        path: "/auth/callback",
        element: <CallbackPage />,
    },
]);
