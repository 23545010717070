import { User, WebStorageStateStore } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";

export const oidcConfig: AuthProviderProps = {
    // auth0
    authority: "https://bom-maven.eu.auth0.com/",
    client_id: "477Q7pALmtWSlopbXCz1AKGOqPEYmOUj",

    redirect_uri: window.location.origin + "/auth/callback",
    post_logout_redirect_uri: window.location.origin,

    // navigate to /cases on successful login

    scope: "openid profile email",
    extraQueryParams: {
        // Needed for Auth0 to get a proper JWT instead of a JWK
        audience: "https://bom-maven.eu.auth0.com/api/v2/",
    },

    userStore: new WebStorageStateStore({ prefix: "maven:", store: localStorage }),

    onSigninCallback: () => window.location.replace("/cases"),
};

export const getUser = () => {
    const oidcStorage = localStorage.getItem(
        `maven:user:https://bom-maven.eu.auth0.com/:477Q7pALmtWSlopbXCz1AKGOqPEYmOUj`,
    );
    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
};
