import { Box, chakra, forwardRef, Heading } from "@chakra-ui/react";
import { PropsWithoutRef } from "react";
import Markdown, { Options } from "react-markdown";
import { QuoteButton } from "../../rag/sources/QuoteButton/quoteButton.tsx";

export const StyledMarkdown = forwardRef<Options, typeof Markdown>((props, ref) => (
    <Box
        as={Markdown}
        display={"flex"}
        flexDir={"column"}
        gap={4}
        components={{
            h1: (props: PropsWithoutRef<JSX.IntrinsicElements["h1"]>) => <Heading as={"h1"} size={"xl"} {...props} />,
            h2: (props: PropsWithoutRef<JSX.IntrinsicElements["h2"]>) => <Heading as={"h2"} size={"lg"} {...props} />,
            h3: (props: PropsWithoutRef<JSX.IntrinsicElements["h3"]>) => <Heading as={"h3"} size={"md"} {...props} />,
            h4: (props: PropsWithoutRef<JSX.IntrinsicElements["h4"]>) => <Heading as={"h4"} size={"sm"} {...props} />,
            h5: (props: PropsWithoutRef<JSX.IntrinsicElements["h5"]>) => <Heading as={"h5"} size={"xs"} {...props} />,
            h6: (props: PropsWithoutRef<JSX.IntrinsicElements["h6"]>) => <Heading as={"h6"} size={"xs"} {...props} />,

            // Horizontally scrolling pre so it doesn't break layout
            pre: (props: PropsWithoutRef<JSX.IntrinsicElements["pre"]>) => (
                <chakra.pre {...props} whiteSpace={"pre-wrap"} bg={"gray.100"} p={4} borderRadius={"md"} />
            ),
            blockquote: (props: PropsWithoutRef<JSX.IntrinsicElements["blockquote"]>) => (
                <chakra.blockquote {...props} borderLeft={"4px solid"} pl={4} borderColor={"gray.300"} />
            ),
            hr: (props: PropsWithoutRef<JSX.IntrinsicElements["hr"]>) => (
                <chakra.hr {...props} borderColor={"gray.300"} />
            ),

            a: (props: PropsWithoutRef<JSX.IntrinsicElements["a"]>) => {
                // Show a button instead of a link if it's a source link specifically.
                // The backend outputs the word "source" as a label so we know for sure. Ex. ("source")[linkurl]
                if (props.children === "source" && props.href) {
                    return <QuoteButton chunkId={props.href} />;
                }
                // If its not a source and just a normal link
                else {
                    return <chakra.a {...props} color={"purple.500"} />;
                }
            },

            p: (props: PropsWithoutRef<JSX.IntrinsicElements["p"]>) => <Box {...props} />,
        }}
        ref={ref}
        {...props}
    />
));
