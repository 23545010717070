import { Button, Card, CardBody, CardHeader, Center, Heading, Stack, Text } from "@chakra-ui/react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";
import { BaseLayout } from "../../components/layouts/baseLayout/BaseLayout.tsx";

interface CallbackProps {}
export const CallbackPage: FC<CallbackProps> = ({}) => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const errorCode = params.get("error");
    const message = params.get("error_description");

    const { signinRedirect } = useAuth();

    if (errorCode) {
        return (
            <BaseLayout>
                <Center flexGrow={1}>
                    {/*<Container>*/}
                    <Card flexGrow={1} maxW={620} p={10}>
                        <CardHeader>
                            <Heading as={"h2"} variant={"h2"}>
                                Login error
                            </Heading>
                        </CardHeader>
                        <CardBody>
                            <Stack gap={6}>
                                {message && <Text>{message}</Text>}

                                <Button
                                    alignSelf={"start"}
                                    colorScheme={"purple"}
                                    rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
                                    onClick={() => signinRedirect({ prompt: "login" })}
                                >
                                    Log in with a different account
                                </Button>
                            </Stack>
                        </CardBody>
                    </Card>
                </Center>
            </BaseLayout>
        );
    }

    return null;
};
