import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { SimilarCompaniesQuery } from "../../sdk/structures/SimilarCompaniesQuery.ts";
import { getApiConnection } from "../app/api.ts";

export const useSimilarCompaniesQuery = (caseId: number, filters: SimilarCompaniesQuery) => {
    return useQuery({
        queryKey: ["similarCompanies", caseId, filters],
        queryFn: async () => {
            return await sdk.functional.$case.similarCompanies.getSimilarCompanies(getApiConnection(), caseId, filters);
        },
    });
};
