import { useQuery } from "@tanstack/react-query";
import { useAuth } from "react-oidc-context";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useCasesQuery = () => {
    const { user } = useAuth();
    return useQuery({
        queryKey: ["cases", user?.profile.sub],
        queryFn: async () => {
            return await sdk.functional.$case.getCases(getApiConnection());
        },
        refetchOnWindowFocus: false,
        retry: false,
    });
};
