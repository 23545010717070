import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useEmployeesQuery = (caseId: number) => {
    return useQuery({
        queryKey: ["employees", caseId],
        queryFn: async () => {
            return await sdk.functional.$case.employees.getCaseEmployees(getApiConnection(), caseId);
        },
        refetchOnWindowFocus: false,
    });
};
