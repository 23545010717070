import { Link, HStack, Icon, LinkBox, LinkOverlay, SkeletonText, Stack, Text, Center } from "@chakra-ui/react";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useAssetUrlQuery } from "../../../lib/queries/useAssetUrlQuery.ts";
import { Source as SourceType } from "../../../lib/queries/useChunksQuery.ts";
import { formatPageNumbers } from "../../../lib/util/formatPageNumbers.tsx";

interface SourceProps {
    source: SourceType;
}

export const Source: FC<SourceProps> = ({ source }) => {
    const { data: url } = useAssetUrlQuery(source.caseId, source.assetId);

    return (
        <>
            <LinkBox>
                <HStack alignItems={"start"}>
                    <Center h={4}>
                        <Icon flexShrink={0} as={FontAwesomeIcon} icon={faFile} color={"purple.500"} />
                    </Center>
                    <Stack gap={0} w={0} flexGrow={1}>
                        <LinkOverlay
                            as={Link}
                            isTruncated={true}
                            color={"purple.500"}
                            lineHeight={4}
                            fontSize={"sm"}
                            href={(url as string) ?? "#"}
                            target={`#${source.assetId}}`}
                        >
                            {source.asset.filename}
                        </LinkOverlay>
                        {/* page numbers */}
                        <Text color={"gray.600"} fontSize={"sm"}>
                            {source.pageNumbers.length > 0 && formatPageNumbers(source.pageNumbers)}
                        </Text>
                    </Stack>
                </HStack>
            </LinkBox>
        </>
    );
};
