import { useMutation } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";
import { queryClient } from "../app/queryClient.ts";

export const useDeleteAssetMutation = (caseId: number, assetId: number) => {
    return useMutation({
        mutationKey: ["deleteAsset", caseId, assetId],
        mutationFn: async () => {
            await sdk.functional.asset.$case.assets.deleteAsset(getApiConnection(), caseId, assetId);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(["assets", caseId]);
        },
    });
};
