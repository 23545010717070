import { Button, Center, Flex, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { FC } from "react";
import MaeveLogo from "../../../../assets/logos/maeve-logo-grey.svg";

interface SuggestionGroup {
    title: string;
    suggestions: Array<{
        icon: string;
        label: string;
        prompt: string;
    }>;
}

const suggestionGroups: SuggestionGroup[] = [
    {
        title: "Value Proposition",
        suggestions: [
            {
                icon: "💡",
                label: "how does the product work?",
                prompt: "How does the product work?",
            },
            {
                icon: "🔖",
                label: "IP and patents",
                prompt: "What are the patents and IP of this company?",
            },
        ],
    },
    {
        title: "Market",
        suggestions: [
            {
                icon: "🛍️",
                label: "target market",
                prompt: "What is the target market?",
            },
            {
                icon: "📈",
                label: "market trends and growth",
                prompt: "What are the market trends and growth?",
            },
        ],
    },
    {
        title: "Traction",
        suggestions: [
            {
                icon: "💼",
                label: "business model",
                prompt: "What is the business model?",
            },
            {
                icon: "🚀",
                label: "go to market plan",
                prompt: "What is the go to market plan?",
            },
        ],
    },
];

interface ChatSuggestionsProps {
    onSuggestion: (prompt: string) => void;
}
export const ChatSuggestions: FC<ChatSuggestionsProps> = ({ onSuggestion }) => {
    return (
        // Outer container, centers the welcome text and suggestion boxes
        <Center flexGrow={1} flexDir={"column"} gap={14}>
            {/* aligns logo, welcome header and subtitle */}
            <Stack gap={3} textAlign={"center"}>
                <Center>
                    <Image src={MaeveLogo} alt={"Maeve logo"} />
                </Center>
                <Heading variant={"h1"} as={"h1"}>
                    Welcome 👋
                </Heading>
                <Text variant={"subheader"} color={"gray.800"}>
                    What would you like to know about the case?
                </Text>
            </Stack>

            {/* aligns multiple suggestion boxes */}
            <Flex gap={"8"}>
                {/* individual suggestion categories */}
                {suggestionGroups.map((group) => (
                    <Stack
                        minW={"324px"}
                        minH={"200px"}
                        bg={"purple.200"}
                        p={6}
                        borderRadius={"3xl"}
                        key={group.title}
                        align={"start"}
                    >
                        {/* suggestion box title/category */}
                        <Text variant={"labelCaps"} color={"purple.600"} mb={2}>
                            {group.title}
                        </Text>

                        {/* suggestion chips / buttons */}
                        {group.suggestions.map((suggestion) => (
                            <Button
                                key={suggestion.label}
                                variant={"chipButton"}
                                leftIcon={<Text>{suggestion.icon}</Text>}
                                onClick={() => onSuggestion(suggestion.prompt)}
                            >
                                {suggestion.label}
                            </Button>
                        ))}
                    </Stack>
                ))}
            </Flex>
        </Center>

        // {/* styling for suggestions sidebar */}
        // {/*{suggestionGroups.map((group) => (*/}
        // {/*    <Stack bg={"purple.200"} p={4} borderRadius={"3xl"} key={group.title} align={"start"}>*/}
        // {/*        <Text variant={"labelCaps"} color={"purple.600"} mb={2}>*/}
        // {/*            {group.title}*/}
        // {/*        </Text>*/}
        // {/*        {group.suggestions.map((suggestion) => (*/}
        // {/*            <Button*/}
        // {/*                key={suggestion.label}*/}
        // {/*                outline={"1px solid"}*/}
        // {/*                outlineColor={"purple.400"}*/}
        // {/*                color={"purple.500"}*/}
        // {/*                bg={"purple.100"}*/}
        // {/*                leftIcon={<Text>{suggestion.icon}</Text>}*/}
        // {/*                _hover={{ bg: "gray.50" }}*/}
        // {/*                _active={{ bg: "gray.300" }}*/}
        // {/*                onClick={() => onSuggestion(suggestion.prompt)}*/}
        // {/*            >*/}
        // {/*                {suggestion.label}*/}
        // {/*            </Button>*/}
        // {/*        ))}*/}
        // {/*    </Stack>*/}
        // {/*))}*/}
    );
};
