/**
 * Extracts source ids from message content and returns them as an array of strings.
 * @param messageContent
 */
export const aggregateSources = (messageContent: string) => {
    // Extract just the ids from source markdown links ex. [source](id) (just id part)
    const regex = /\[source\]\(([a-z0-9#-]+)\)/gi;
    // const sources: string[] = [];

    // Find all matches of the regex in the message content
    const matches = messageContent.matchAll(regex);

    if (!matches) return [];
    // If there are matches, extract the id part and push it to the sources array
    const chunkIds = Array.from(matches).map((match) => match[1]);
    // deduplicate
    return [...new Set(chunkIds)];
};
