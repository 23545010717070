/**
 * @packageDocumentation
 * @module api.functional.bot.sessions.events
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

/**
 * @controller BotController.getChatEvents
 * @path GET /bot/sessions/:sessionId/events
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getChatEvents(connection: IConnection, sessionIdParam: string): Promise<getChatEvents.Output> {
    return PlainFetcher.fetch(connection, {
        ...getChatEvents.METADATA,
        template: getChatEvents.METADATA.path,
        path: getChatEvents.path(sessionIdParam),
    });
}
export namespace getChatEvents {
    export type Output = any;

    export const METADATA = {
        method: "GET",
        path: "/bot/sessions/:sessionId/events",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (sessionIdParam: string) =>
        `/bot/sessions/${encodeURIComponent(sessionIdParam ?? "null")}/events`;
}
