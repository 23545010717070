import {
    Center,
    Heading,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Spacer,
    Spinner,
    Stack,
} from "@chakra-ui/react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fuse from "fuse.js";
import { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAssetsQuery } from "../../lib/queries/useAssetsQuery.ts";
import { getAssets } from "../../sdk/functional/asset/$case/assets";
import { Asset } from "./components/Asset.tsx";
import { NewAssetPopover } from "./components/newAssetPopover/NewAssetPopover.tsx";
import { SideNavLayout } from "../../components/layouts/sideNavLayout/SideNavLayout.tsx";
import { EmptyState } from "../../components/misc/emptyState/EmptyState.tsx";

interface AssetsProps {}
export const AssetsPage: FC<AssetsProps> = ({}) => {
    const { caseId } = useParams<"caseId">();
    if (!caseId) throw new Error("No case id provided");

    const { data: assets, isSuccess, isLoading } = useAssetsQuery(parseInt(caseId));

    const [query, setQuery] = useState("");

    const fuse = useMemo(
        () =>
            new Fuse(assets ?? ([] as getAssets.Output), {
                keys: ["filename"],
            }),
        [assets],
    );

    const filteredAssets = query.length > 0 ? fuse.search(query).map((r) => r.item) : assets;

    return (
        <SideNavLayout>
            <Stack gap={10}>
                {/*title, search, new asset*/}
                <HStack gap={4}>
                    <Heading as={"h1"} size={"lg"}>
                        Assets
                    </Heading>
                    <Spacer />

                    <InputGroup maxW={80} variant={"ghost"}>
                        <InputLeftElement pointerEvents="none">
                            <Icon icon={faMagnifyingGlass} as={FontAwesomeIcon} />
                        </InputLeftElement>
                        <Input
                            variant={"gray"}
                            placeholder={"Search assets"}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                    </InputGroup>

                    <NewAssetPopover />
                </HStack>

                {isLoading && <Spinner />}

                {/*assets*/}
                {isSuccess && filteredAssets?.length && (
                    <Stack gap={6}>
                        {isSuccess &&
                            filteredAssets &&
                            filteredAssets.length > 0 &&
                            filteredAssets.map((asset) => <Asset key={asset.id} asset={asset} />)}
                    </Stack>
                )}

                {/* Empty state */}
                {/* No assets uploaded */}
                {isSuccess && assets?.length === 0 && (
                    <Center h="70vh">
                        <EmptyState>No assets added to this case</EmptyState>
                    </Center>
                )}

                {/* no search results */}
                {isSuccess && assets?.length > 0 && filteredAssets?.length === 0 && (
                    <Center h="70vh">
                        <EmptyState>No assets found</EmptyState>
                    </Center>
                )}
            </Stack>
        </SideNavLayout>
    );
};
