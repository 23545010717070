import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Center,
    Container,
    FormControl,
    Heading,
    Icon,
    Input,
    Stack,
    StackDivider,
    useToast,
} from "@chakra-ui/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCreateCaseWithPitchdeckMutation } from "../../lib/mutations/useCreateCaseWithPitchdeckMutation.ts";
import { UploadBox } from "../../components/files/uploadBox/UploadBox.tsx";
import { SideNavLayout } from "../../components/layouts/sideNavLayout/SideNavLayout.tsx";

interface FormInput {
    name: string;
    website?: string;
    pitchdeck: File;
}

interface NewCaseProps {}
export const NewCasePage: FC<NewCaseProps> = ({}) => {
    const navigate = useNavigate();
    const { mutate: createCase, isLoading: isCreating, data: newCase, error } = useCreateCaseWithPitchdeckMutation();
    const { register, handleSubmit, watch, setValue, formState, resetField } = useForm<FormInput>({});

    const onSubmit: SubmitHandler<FormInput> = (data) => {
        createCase({
            name: data.name,
            website: data.website && data.website.length > 0 ? data.website : undefined,
            pitchdeck: data.pitchdeck,
        });
    };

    const invalidFileToast = useToast({
        title: "Invalid file",
        description: "Only PDF files under 50MB are allowed.",
        status: "error",
        position: "bottom-right",
    });

    const caseCreatedToast = useToast({
        title: "Case created",
        description: "Your case has been created.",
        status: "success",
        position: "bottom-right",
    });

    const creationFailedToast = useToast({
        title: "Error creating case",
        description: "An error occurred while creating the case.",
        status: "error",
        position: "bottom-right",
    });

    // Since we cannot register to UploadBox, we do it here
    useEffect(() => {
        register("pitchdeck", { required: true });
    }, [register]);

    // On case creation, move to the case page
    useEffect(() => {
        if (!newCase) return;
        caseCreatedToast();
        navigate(`/cases/${newCase.id}`);
    }, [navigate, newCase, caseCreatedToast]);

    // On error, show a toast and mark the field as invalid
    useEffect(() => {
        if (!error) return;
        creationFailedToast({ description: (error as Error).message });
    }, [error, creationFailedToast]);

    return (
        <SideNavLayout hasBackButton>
            <Stack gap={6}>
                <Card shadow={"none"} p={10} borderRadius={40}>
                    <CardHeader mb={4}>
                        <Container maxW={"container.md"}>
                            <Center>
                                <Heading variant={"h1"}>New case</Heading>
                            </Center>
                        </Container>
                    </CardHeader>
                    <CardBody>
                        <Container maxW={"container.md"}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Stack gap={4}>
                                    <Heading as={"h2"} size={"md"}>
                                        Company details
                                    </Heading>
                                    <FormControl>
                                        {/*<FormLabel>Name</FormLabel>*/}
                                        <Input
                                            {...register("name", { required: true, minLength: 3 })}
                                            isInvalid={!!formState.errors.name}
                                            placeholder={"Name"}
                                            variant={"filled"}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        {/*<FormLabel>Website</FormLabel>*/}
                                        <Input
                                            {...register("website")}
                                            isInvalid={!!formState.errors.website}
                                            placeholder={"Website"}
                                            variant={"filled"}
                                        />
                                    </FormControl>
                                    <StackDivider h={4} />
                                    <Heading as={"h2"} size={"md"}>
                                        Pitch Deck
                                    </Heading>

                                    <UploadBox
                                        onDropRejected={() => invalidFileToast()}
                                        isError={!!formState.errors.pitchdeck}
                                        label={"Drag your pitch deck here or click to select"}
                                        currentFile={watch("pitchdeck")}
                                        onClear={() => resetField("pitchdeck")}
                                        onDropAccepted={(files) => setValue("pitchdeck", files[0])}
                                    />

                                    <StackDivider h={4} />

                                    <Center>
                                        <Button
                                            isDisabled={isCreating}
                                            type={"submit"}
                                            size={"lg"}
                                            colorScheme={"purple"}
                                            leftIcon={<Icon as={FontAwesomeIcon} icon={faPlus} />}
                                            alignSelf={"start"}
                                        >
                                            Create case
                                        </Button>
                                    </Center>
                                </Stack>
                            </form>
                        </Container>
                    </CardBody>
                </Card>
            </Stack>
        </SideNavLayout>
    );
};
