import { HStack, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { SimilarCompaniesQuery } from "../../../sdk/structures/SimilarCompaniesQuery.ts";
import { FilterPopover } from "../../ui/filterPopover/FilterPopover.tsx";

const options = [
    { value: "1", continent: undefined, country: undefined, label: "All", tagLabel: null },
    { value: "2", continent: undefined, country: "United States", label: "United States", tagLabel: "US" },
    { value: "3", continent: "Europe", country: undefined, label: "Europe", tagLabel: "EU" },
];

interface LocationFilterProps {
    continent?: SimilarCompaniesQuery["continent"];
    country?: SimilarCompaniesQuery["country"];
    onChange: (continent?: SimilarCompaniesQuery["continent"], country?: SimilarCompaniesQuery["country"]) => void;
}

export const LocationFilter: FC<LocationFilterProps> = ({ continent, country, onChange }) => {
    const { watch, setValue } = useForm<{
        continent?: SimilarCompaniesQuery["continent"];
        country?: SimilarCompaniesQuery["country"];
    }>({
        defaultValues: { country, continent },
    });

    // Set form values when incoming values change
    useEffect(() => {
        setValue("continent", continent);
        setValue("country", country);
    }, [continent, country, setValue]);

    const cancel = () => {
        setValue("continent", continent);
        setValue("country", country);
        apply();
    };

    const clear = () => {
        setValue("continent", undefined);
        setValue("country", undefined);
        apply();
    };

    const apply = () => {
        onChange(watch("continent"), watch("country"));
    };

    const locationToOption = (continent?: string, country?: string) =>
        options.find((option) => option.continent === continent && option.country === country);

    const activeOption = locationToOption(continent, country);

    return (
        <HStack>
            <FilterPopover
                buttonLabel={"Location"}
                tagLabel={activeOption?.tagLabel}
                title={"Location"}
                onClear={clear}
                onCancel={cancel}
                onApply={apply}
            >
                <RadioGroup
                    colorScheme={"purple"}
                    value={locationToOption(watch("continent"), watch("country"))?.value}
                    onChange={(value) => {
                        const option = options.find((option) => option.value === value);
                        setValue("continent", option?.continent as SimilarCompaniesQuery["continent"]);
                        setValue("country", option?.country as SimilarCompaniesQuery["country"]);
                    }}
                >
                    <Stack>
                        {options.map((option) => (
                            <Radio key={option.value} value={option.value}>
                                {option.label}
                            </Radio>
                        ))}
                    </Stack>
                </RadioGroup>
            </FilterPopover>
        </HStack>
    );
};
