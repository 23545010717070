/**
 * Mapping of location names to their respective abbreviations.
 */
export const locationMapping: Record<string, string> = {
    // Continents
    "North America": "NA",
    Europe: "EU",

    // Countries
    "United States": "US",
};
