import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import Smartlook from "smartlook-client";

// Only run in prod to avoid unnecessary recordings
if (import.meta.env.PROD) {
    Smartlook.init(import.meta.env.VITE_SMARTLOOK_KEY, {});
    Smartlook.record({ forms: true, numbers: true });
}

export const useSmartlook = () => {
    const { user } = useAuth();

    // Identify the user when they log in
    useEffect(() => {
        if (!import.meta.env.PROD) return;
        if (!user) return;
        Smartlook.identify(user.profile.sub, { name: user.profile.name ?? "Unknown user" });
    }, [user]);
};
