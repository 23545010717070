import {
    Avatar,
    Box,
    Button,
    Center,
    HStack,
    Icon,
    Image,
    Menu,
    MenuButton,
    Spacer,
    Stack,
    Text,
} from "@chakra-ui/react";
import { faCommentDots, faFileLines } from "@fortawesome/free-regular-svg-icons";
import { faAlignLeft, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useAuth } from "react-oidc-context";
import { Link, useLocation, useParams } from "react-router-dom";
import maeveLogoTriangle from "../../../assets/logos/maeve-logo-triangle.svg";
import maeveLogo from "../../../assets/logos/maeve-logo.svg";
import { UserDropdown } from "../user/UserDropdown.tsx";
import { CaseDropdown } from "./caseDropdown/CaseDropdown.tsx";

interface SideNavContentProps {
    expanded: boolean;
}
export const SideNavContent: FC<SideNavContentProps> = ({ expanded }) => {
    const { isAuthenticated, user } = useAuth();
    const { caseId: caseIdStr } = useParams();

    const caseId = parseInt(caseIdStr || "");

    const path = useLocation().pathname;

    return (
        <Box
            transitionProperty={"width"}
            transitionTimingFunction={"ease-in-out"}
            transitionDuration="350ms"
            h="full"
            p="4"
            w={expanded ? "280px" : "88px"}
            overflow={"hidden"}
        >
            <Stack gap={12} justifyContent="flex-start" h="full" alignItems="stretch">
                {/*Only show case picker and links when a case has been selected*/}
                {caseId && (
                    <>
                        <CaseDropdown />

                        <Stack>
                            {expanded && (
                                <Text align="start" mb={3} variant="labelCaps">
                                    Case
                                </Text>
                            )}
                            {/* Menu/page buttons */}
                            {/* Company info button */}
                            <Button
                                as={Link}
                                to={`/cases/${caseId}`}
                                isActive={path === `/cases/${caseId}`}
                                variant={"menuButton"}
                                iconSpacing="4"
                                leftIcon={<Icon size={"xl"} as={FontAwesomeIcon} icon={faAlignLeft} />}
                            >
                                {expanded && "Company info"}
                            </Button>
                            {/* Assets button */}
                            <Button
                                as={Link}
                                to={`/cases/${caseId}/assets`}
                                isActive={path === `/cases/${caseId}/assets`}
                                variant={"menuButton"}
                                iconSpacing={4}
                                leftIcon={<Icon size={"xl"} as={FontAwesomeIcon} icon={faPaperclip} />}
                            >
                                {expanded && "Assets"}
                            </Button>

                            {/* Memo button */}
                            <Button
                                as={Link}
                                to={`/cases/${caseId}/memo`}
                                isActive={path === `/cases/${caseId}/memo`}
                                variant={"menuButton"}
                                iconSpacing={4}
                                leftIcon={<Icon size={"xl"} as={FontAwesomeIcon} icon={faFileLines} />}
                            >
                                {expanded && "Memo"}
                            </Button>

                            {/* Maeve chat button */}
                            <Button
                                as={Link}
                                to={`/cases/${caseId}/chat`}
                                isActive={path === `/cases/${caseId}/chat`}
                                variant={"menuButton"}
                                iconSpacing={4}
                                leftIcon={<Icon size={"xl"} as={FontAwesomeIcon} icon={faCommentDots} />}
                            >
                                {expanded && "Maeve"}
                            </Button>
                        </Stack>
                    </>
                )}
                <Spacer />
                {/* User name + logout button */}
                <Stack gap={8}>
                    {isAuthenticated && user && (
                        <Menu variant={"userMenu"} placement={"end-end"} autoSelect={false}>
                            <MenuButton>
                                <HStack gap={2}>
                                    <Avatar
                                        size={"sm"}
                                        boxSize={"40px"}
                                        name={user.profile.name}
                                        src={user.profile.picture}
                                    />
                                    {expanded && (
                                        <Stack w="0" flexGrow={1} align="flex-start" gap={0}>
                                            <Text
                                                maxW="full"
                                                isTruncated={true}
                                                variant="labelBold"
                                                color="white"
                                                size="sm"
                                            >
                                                {user.profile.name}
                                            </Text>
                                            <Text maxW="full" isTruncated={true} variant="label" colorScheme="gray.500">
                                                {user.profile.email}
                                            </Text>
                                        </Stack>
                                    )}
                                </HStack>
                            </MenuButton>
                            <UserDropdown />
                        </Menu>
                    )}
                    {/* Maeve logo */}
                    <Center>
                        <Image src={expanded ? maeveLogo : maeveLogoTriangle} alt="Maeve logo" />
                    </Center>
                </Stack>
            </Stack>
        </Box>
    );
};
