/**
 * Formats page numbers into a human-readable string.
 * Ex. [1, 2, 3, 5, 6, 7, 8, 9, 10, 28, 32] -> "page 1-3, 5-10, 28, 32"
 * @param pageNumbers
 */
export const formatPageNumbers = (pageNumbers: number[]) => {
    //  Deduplicate and sort page numbers
    const sortedNumbers = Array.from(new Set(pageNumbers)).sort((a, b) => a - b);
    const ranges: string[] = [];

    let rangeStart = sortedNumbers[0];
    let rangeEnd = sortedNumbers[0];

    for (const page of sortedNumbers) {
        // If the page is the next page in the range, extend the range
        if (page === rangeEnd) {
            continue;
        }
        // If the page is not the next page in the range, start a new range
        else if (page === rangeEnd + 1) {
            rangeEnd = page;
        } else {
            // If the range is only one page, add the single page number
            ranges.push(rangeStart === rangeEnd ? `${rangeStart}` : `${rangeStart}-${rangeEnd}`);
            rangeStart = page;
            rangeEnd = page;
        }
    }

    // Add the last range
    ranges.push(rangeStart === rangeEnd ? `${rangeStart}` : `${rangeStart}-${rangeEnd}`);
    // Return the formatted page numbers
    return "page " + ranges.join(", ");
};
