import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useEffect } from "react";
import { getAuthHeaders } from "../../../lib/app/api.ts";
import { queryClient } from "../../../lib/app/queryClient.ts";

export const useCaseSSE = (caseId: number) => {
    useEffect(() => {
        const ctrl = new AbortController();

        fetchEventSource(`${import.meta.env.VITE_API_BASE}/case/${caseId}/events`, {
            headers: getAuthHeaders(),
            signal: ctrl.signal,
            openWhenHidden: true,
            onmessage: (e) => {
                switch (e.event) {
                    case "case.updateDescription":
                        queryClient.invalidateQueries(["case", caseId]).catch();
                        queryClient.invalidateQueries(["similarCompanies", caseId]).catch();
                        queryClient.invalidateQueries(["attempts", caseId]).catch();
                        break;
                    case "case.updateEmployees":
                        queryClient.invalidateQueries(["employees", caseId]).catch();
                        queryClient.invalidateQueries(["attempts", caseId]).catch();
                        break;
                }
            },
            onclose: () => console.log("SSE closed by server"),
        }).catch();

        return () => ctrl.abort();
    }, [caseId]);
};
