import { PlacementWithLogical } from "@chakra-ui/popper";
import {
    Button,
    ButtonGroup,
    Flex,
    HStack,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Spacer,
    Stack,
    Tag,
    TagLabel,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, PropsWithChildren, ReactElement, ReactNode } from "react";

interface FilterPopoverProps extends PropsWithChildren {
    buttonLabel: string | ReactNode;
    tagLabel?: string | null;
    title: string;
    onClear?: () => void;
    onCancel: () => void;
    onApply: () => void;
    alignment?: PlacementWithLogical;
    icon?: ReactElement;
}
export const FilterPopover: FC<FilterPopoverProps> = ({
    tagLabel,
    buttonLabel,
    title,
    onClear,
    onCancel,
    onApply,
    children,
    alignment = "bottom-start",
    icon,
}) => {
    const { isOpen, onClose, onToggle } = useDisclosure({});

    return (
        <Popover isOpen={isOpen} placement={alignment} onClose={onClose}>
            <PopoverTrigger>
                <Button
                    variant={"dropdown"}
                    isActive={isOpen}
                    onClick={onToggle}
                    gap={2}
                    iconSpacing={1}
                    rightIcon={icon ?? <FontAwesomeIcon icon={faChevronDown} />}
                >
                    {/* If buttonLabel is a string, render it as text, otherwise render it as a component */}
                    {typeof buttonLabel === "string" && <Text>{buttonLabel}</Text>}
                    {typeof buttonLabel !== "string" && buttonLabel}

                    {tagLabel && (
                        <Tag variant={"filter"} size={"sm"}>
                            <TagLabel>{tagLabel}</TagLabel>
                        </Tag>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>
                    <HStack>
                        <Text variant={"title"}>{title}</Text>
                        <Spacer />
                        {onClear && (
                            <Button
                                color={"purple.500"}
                                variant={"link"}
                                onClick={() => {
                                    onClear();
                                    onClose();
                                }}
                            >
                                Clear
                            </Button>
                        )}
                    </HStack>
                </PopoverHeader>

                <PopoverBody>
                    <Stack gap={5}>
                        {children}

                        <Flex justify={"flex-end"}>
                            <ButtonGroup>
                                <Button
                                    bg={"gray.300"}
                                    onClick={() => {
                                        onCancel();
                                        onClose();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    _hover={{ bg: "purple.600" }}
                                    color="white"
                                    bg={"purple.500"}
                                    onClick={() => {
                                        onApply();
                                        onClose();
                                    }}
                                >
                                    Apply
                                </Button>
                            </ButtonGroup>
                        </Flex>
                    </Stack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};
