import {
    Button,
    Card,
    CardBody,
    Center,
    Heading,
    HStack,
    Icon,
    IconButton,
    Img,
    Spinner,
    Stack,
    Tag,
    TagLabel,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { faCheckCircle, faCircleXmark, faFile, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useDeleteAssetMutation } from "../../../lib/mutations/useDeleteAssetMutation.ts";
import { useAssetUrlQuery } from "../../../lib/queries/useAssetUrlQuery.ts";
import { getAsset } from "../../../sdk/functional/asset/$case/assets";
import FileIcon from "../../../assets/images/file.png";

interface AssetProps {
    asset: getAsset.Output;
}
export const Asset: FC<AssetProps> = ({ asset }) => {
    const { data: url } = useAssetUrlQuery(asset.case_id, asset.id);
    const { mutate: deleteAsset, isLoading: isDeletingAsset } = useDeleteAssetMutation(asset.case_id, asset.id);

    return (
        <Card variant={"flat"}>
            <CardBody>
                <HStack gap={6}>
                    <Center w={160} h={120} bg={"purple.100"} borderRadius={"lg"} mt={-2} ml={-2} mb={-2}>
                        <Img src={FileIcon} h={12} />
                    </Center>
                    <Stack flexGrow={1} gap={2}>
                        <HStack>
                            <Icon as={FontAwesomeIcon} icon={faFile} color={"gray.500"} />
                            <Text variant={"labelCaps"} color={"gray.600"}>
                                Uploaded {DateTime.fromISO(asset.created_at).toLocaleString(DateTime.DATE_MED)}
                            </Text>
                        </HStack>
                        <Link to={url ?? "#"} target={"_blank"}>
                            <Heading size={"md"} noOfLines={1}>
                                {asset.filename}
                            </Heading>
                        </Link>
                    </Stack>

                    {/*Hidden from design but I predict this will come back*/}
                    {/*{url && (*/}
                    {/*    <Link href={url} target={"_blank"} alignSelf={"start"}>*/}
                    {/*        <IconButton*/}
                    {/*            aria-label={"Download"}*/}
                    {/*borderRadius={"lg"}*/}
                    {/*            colorScheme={"gray"}*/}
                    {/*            size={"sm"}*/}
                    {/*            bg={"gray.300"}*/}
                    {/*            _hover={{ bg: "gray.400" }}*/}
                    {/*            icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}*/}
                    {/*        />*/}
                    {/*    </Link>*/}
                    {/*)}*/}

                    <HStack gap={2} alignSelf={"start"} flexShrink={0}>
                        {asset.index_pending && (
                            <Tag variant={"loader"}>
                                <Spinner color={"purple.500"} speed={"1s"} size="xs" boxSize={3} />
                                <TagLabel>Processing</TagLabel>
                            </Tag>
                        )}
                        {asset.index_error && (
                            <Tooltip label={asset.index_error} hasArrow placement={"top"}>
                                <Tag variant={"error"} flexShrink={0}>
                                    <FontAwesomeIcon icon={faCircleXmark} color={"error.400"} size={"sm"} />
                                    <TagLabel>Error</TagLabel>
                                </Tag>
                            </Tooltip>
                        )}
                        {!asset.index_pending && !asset.index_error && (
                            <Tag alignSelf={"start"} flexShrink={0}>
                                <FontAwesomeIcon icon={faCheckCircle} color={"gray.600"} size={"sm"} />
                                <TagLabel>Processed</TagLabel>
                            </Tag>
                        )}

                        {/*Delete asset, only when asset isn't being indexed*/}
                        {!asset.index_pending && (
                            <Tooltip label={"Delete asset"} hasArrow placement={"top"}>
                                <IconButton
                                    boxSize={8}
                                    minW={0}
                                    minH={0}
                                    flexShrink={0}
                                    alignSelf={"start"}
                                    isLoading={isDeletingAsset}
                                    rounded={"xl"}
                                    icon={<Icon as={FontAwesomeIcon} icon={faTrashCan} />}
                                    aria-label={"Delete"}
                                    onClick={() => {
                                        if (!confirm(`Are you sure you want to delete ${asset.filename}?`)) return;
                                        deleteAsset();
                                    }}
                                />
                            </Tooltip>
                        )}
                    </HStack>
                </HStack>
            </CardBody>
        </Card>
    );
};
