import { Flex } from "@chakra-ui/react";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ChatBox } from "../../components/chat/chatBox/ChatBox.tsx";
import { SideNavLayout } from "../../components/layouts/sideNavLayout/SideNavLayout.tsx";

// const expandedAtom = atomWithStorage<boolean>("expanded", true, undefined, {
//     getOnInit: true,
// });
interface MaeveChatProps {}
export const MaeveChatPage: FC<MaeveChatProps> = () => {
    const { caseId: caseIdStr } = useParams();
    const caseId = parseInt(caseIdStr || "");

    // const [expanded, setExpanded] = useAtom(expandedAtom);
    return (
        <SideNavLayout hasContainer={true}>
            {/* chat area */}
            <ChatBox caseId={caseId} />

            {/* sidebar with suggestions, for next cycle */}
            {/*{expanded && <SuggestionSidebar onClose={() => setExpanded(false)} />}*/}
        </SideNavLayout>
    );
};
