import {
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverProps,
    PopoverTrigger,
    Text,
} from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";
import { Sources } from "../Sources/Sources.tsx";

interface SourcesPopoverProps extends PropsWithChildren {
    heading?: string;
    chunkIds: string[];
    trigger?: PopoverProps["trigger"];
}

export const SourcesPopover: FC<SourcesPopoverProps> = ({ heading, chunkIds, children, trigger = "hover" }) => {
    return (
        <Popover trigger={trigger as PopoverProps["trigger"]} placement={"bottom-start"} isLazy>
            <PopoverTrigger>{children}</PopoverTrigger>

            <PopoverContent p={2} rounded={"xl"} shadow={"2xl"}>
                {heading && (
                    <PopoverHeader>
                        <Text fontSize={"sm"} variant={"labelCaps"}>
                            {heading}
                        </Text>
                    </PopoverHeader>
                )}
                <PopoverBody>
                    <Sources chunkIds={chunkIds} />
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};
