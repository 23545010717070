import { EventSourceMessage, fetchEventSource } from "@microsoft/fetch-event-source";
import { useMutation } from "@tanstack/react-query";
import { getMemo } from "../../sdk/functional/memo/cases/memos";
import { getAuthHeaders } from "../app/api.ts";
import { queryClient } from "../app/queryClient.ts";

export const useRegenerateTopicMutation = (caseId: number, topic: string) => {
    return useMutation({
        mutationKey: ["regenerateMemo", caseId],
        mutationFn: async () => {
            const onMemoCreate = (memo: getMemo.Output) => {
                queryClient.setQueriesData<getMemo.Output>(["memo", memo.case_id, memo.topic], () => memo);
            };

            const onMemoAddText = (data: { caseId: number; topic: string; text: string }) => {
                queryClient.setQueriesData<getMemo.Output>(["memo", data.caseId, data.topic], (memo) => {
                    if (!memo) return memo;
                    return { ...memo, text: memo.text + data.text }; // Force a re-render by creating a new object
                });
            };

            const onMessage = async (msg: EventSourceMessage) => {
                if (msg.event === "memo.create") onMemoCreate(JSON.parse(msg.data));
                if (msg.event === "memo.addText") onMemoAddText(JSON.parse(msg.data));
                if (msg.event === "error") throw new Error(msg.data);
            };

            await fetchEventSource(import.meta.env.VITE_API_BASE + `/memo/cases/${caseId}/topic/${topic}/regenerate`, {
                method: "POST",
                headers: { "Content-Type": "application/json", ...getAuthHeaders() },
                onmessage: onMessage,
                onerror: (err: unknown) => {
                    console.warn("error", err);
                    throw err; // Stop fetchEventSource from retrying
                },
                // onclose: () => {
                //     console.log("sse closed");
                // },
            });
        },

        retry: false,
    });
};
