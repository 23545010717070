/**
 * @packageDocumentation
 * @module api.functional.$case.similarCompanies
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Resolved } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { SimilarCompaniesQuery } from "../../../structures/SimilarCompaniesQuery";
import type { SimilarCompany } from "../../../structures/SimilarCompany";

/**
 * @controller CaseController.getSimilarCompanies
 * @path GET /case/:id/similarCompanies
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getSimilarCompanies(
    connection: IConnection,
    id: number,
    __1: getSimilarCompanies.Query,
): Promise<getSimilarCompanies.Output> {
    return PlainFetcher.fetch(connection, {
        ...getSimilarCompanies.METADATA,
        template: getSimilarCompanies.METADATA.path,
        path: getSimilarCompanies.path(id, __1),
    });
}
export namespace getSimilarCompanies {
    export type Query = Resolved<SimilarCompaniesQuery>;
    export type Output = SimilarCompany[];

    export const METADATA = {
        method: "GET",
        path: "/case/:id/similarCompanies",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (id: number, __1: getSimilarCompanies.Query) => {
        const variables: URLSearchParams = new URLSearchParams();
        for (const [key, value] of Object.entries(__1 as any))
            if (undefined === value) continue;
            else if (Array.isArray(value)) value.forEach((elem: any) => variables.append(key, String(elem)));
            else variables.set(key, String(value));
        const location: string = `/case/${encodeURIComponent(id ?? "null")}/similarCompanies`;
        return 0 === variables.size ? location : `${location}?${variables.toString()}`;
    };
}
