import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getAsset } from "../../sdk/functional/asset/$case/assets";
import { getApiConnection } from "../app/api.ts";

export interface Source {
    caseId: number;
    assetId: number;
    asset: getAsset.Output;
    pageNumbers: number[];
}

export const useChunksQuery = (chunkIds: string[]) => {
    return useQuery({
        queryKey: ["chunk", chunkIds],
        queryFn: async () => {
            // allSettled so we also get rejected promises
            const promises = await Promise.allSettled(
                chunkIds.map((chunkId) => sdk.functional.ai.chunk.getChunkInfo(getApiConnection(), chunkId)),
            );

            const sources = promises
                // Filter only fulfilled promises
                .filter((p) => p.status === "fulfilled")
                // Map to value
                .map((p) => p.value)
                // Deduplicate chunks, one asset with multiple page numbers
                .reduce((acc, chunk) => {
                    const existing = acc.find((c) => c.assetId === chunk.assetId);
                    if (existing && chunk.pageNumber) {
                        existing.pageNumbers.push(chunk.pageNumber);
                    } else {
                        acc.push({
                            caseId: chunk.caseId,
                            assetId: chunk.asset.id,
                            asset: chunk.asset,
                            pageNumbers: [
                                // Only add page number if it exists
                                ...(chunk.pageNumber ? [chunk.pageNumber] : []),
                            ],
                        });
                    }
                    return acc;
                }, [] as Source[]);

            // Sort pages
            sources.forEach((source) => source.pageNumbers.sort((a, b) => b - a));

            return sources;
        },
        refetchOnWindowFocus: false,
        staleTime: Infinity, // Chunks don't really change
        retryOnMount: false,
    });
};
