/**
 * @packageDocumentation
 * @module api.functional.$case.employees
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Format } from "typia/lib/tags/Format";

/**
 * @controller CaseController.getCaseEmployees
 * @path GET /case/:id/employees
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getCaseEmployees(connection: IConnection, id: number): Promise<getCaseEmployees.Output> {
    return PlainFetcher.fetch(connection, {
        ...getCaseEmployees.METADATA,
        template: getCaseEmployees.METADATA.path,
        path: getCaseEmployees.path(id),
    });
}
export namespace getCaseEmployees {
    export type Output = {
        id: number;
        name: string;
        title: null | string;
        image_url: null | string;
        linkedin_url: null | string;
        email: null | string;
        is_founder: null | boolean;
        is_executive: null | boolean;
        created_at: string & Format<"date-time">;
        case_id: number;
    }[];

    export const METADATA = {
        method: "GET",
        path: "/case/:id/employees",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (id: number) => `/case/${encodeURIComponent(id ?? "null")}/employees`;
}
