import { SkeletonText, Spinner, Stack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FC, useEffect, useRef, useState } from "react";
import { useStickyBottom } from "../../../lib/hooks/useStickyBottom.ts";
import { useSendChatMessageMutation } from "../../../lib/mutations/useSendChatMessageMutation.ts";
import { useChatMessagesQuery } from "../../../lib/queries/useChatMessagesQuery.ts";
import { useChatSessionsQuery } from "../../../lib/queries/useChatSessionsQuery.ts";
import { ChatSuggestions } from "../../../pages/maeveChatPage/components/chatSuggestions/ChatSuggestions.tsx";
import { ChatBubble } from "../chatBubble/ChatBubble.tsx";
import { ChatInput } from "../chatInput/ChatInput.tsx";

interface BotProps {
    caseId: number;
}
export const ChatBox: FC<BotProps> = ({ caseId }) => {
    // Todo: Sidebar component for suggestions.
    // Chat can be its own thing, just bubbles and inputs taking max size
    // Chat then takes a sessionId

    const { data: sessions } = useChatSessionsQuery(caseId);
    const [activeSessionId, setActiveSessionId] = useState<number>();

    const { mutateAsync: sendChatMessage } = useSendChatMessageMutation();

    // Once sessions are loaded, set the active session to the first one
    useEffect(() => setActiveSessionId(sessions?.[0]?.id), [sessions]);

    const {
        data: chatMessages,
        isLoading: messagesLoading,
        isFetching: messagesFetching,
    } = useChatMessagesQuery(activeSessionId);

    // Sticky scroll
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    useStickyBottom(scrollContainerRef, messagesLoading);

    return (
        <Stack flex={1} flexDirection={"column"} h="full" gap={8}>
            <Stack
                ref={scrollContainerRef}
                flexGrow={1}
                overflow={"scroll"}
                h={0}
                css={{
                    "&::-webkit-scrollbar": { display: "none" },
                }}
                gap={4}
                pos={"relative"}
                pt={8}
            >
                {!messagesLoading && chatMessages?.length === 0 && (
                    <ChatSuggestions
                        onSuggestion={async (prompt) => {
                            if (!activeSessionId) return;
                            await sendChatMessage({
                                sessionId: activeSessionId,
                                content: prompt,
                            });
                        }}
                    />
                )}

                {/* messages loading */}
                {messagesFetching && <Spinner pos={"absolute"} right={0} top={0} size={"sm"} opacity={0.1} />}

                {/* message being made */}
                {messagesLoading && <SkeletonText noOfLines={4} />}

                {/* actual message bubbles rendered */}
                {chatMessages?.map((message) => (
                    <motion.div
                        key={message.id}
                        initial={{ opacity: 0, scale: 0.98 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.25, ease: "easeOut" }}
                    >
                        <ChatBubble message={message} />
                    </motion.div>
                ))}
            </Stack>

            {/* input chat bar */}
            {activeSessionId && <ChatInput activeSessionId={activeSessionId} />}
        </Stack>
    );
};
