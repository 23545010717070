import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useCaseQuery = (id: number) => {
    return useQuery({
        queryKey: ["case", id],
        queryFn: async () => {
            return await sdk.functional.$case.getCase(getApiConnection(), id);
        },
        refetchOnWindowFocus: false,
    });
};
