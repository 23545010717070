import { useMutation } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";
import { queryClient } from "../app/queryClient.ts";

export const useRegenerateCaseMutation = (caseId: number) => {
    return useMutation({
        mutationKey: ["regenerateCase", caseId],
        mutationFn: async () => {
            await sdk.functional.$case.regenerate(getApiConnection(), caseId);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(["case", caseId]);
            await queryClient.invalidateQueries(["attempts", caseId]);
        },
    });
};
