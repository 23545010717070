import { Button, Card, CardBody, Center, Heading, Icon, Spacer } from "@chakra-ui/react";
import { faArrowRight, faFlask } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { useTitle } from "react-use";
import { BaseLayout } from "../../components/layouts/baseLayout/BaseLayout.tsx";

interface HomeProps {}
export const LandingPage: FC<HomeProps> = () => {
    useTitle("Home - Maven");

    const { signinRedirect, isAuthenticated } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) navigate("/cases", { replace: true });
    }, [isAuthenticated, navigate]);

    return (
        <BaseLayout>
            <Center flexGrow={1}>
                {/*<Container>*/}
                <Card flexGrow={1} maxW={620} borderRadius={40}>
                    <CardBody>
                        <Center p={10} flexDir={"column"} gap={8}>
                            <Center boxSize={20} bg={"purple.100"} borderRadius={"3xl"} color={"purple.500"}>
                                <Icon as={FontAwesomeIcon} icon={faFlask} boxSize={8} />
                            </Center>

                            <Heading variant={"h1"}>Welcome to Maven</Heading>
                            <Spacer />
                            <Button
                                colorScheme={"purple"}
                                rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
                                onClick={() => signinRedirect()}
                            >
                                Log in
                            </Button>
                        </Center>
                    </CardBody>
                </Card>
                {/*</Container>*/}
            </Center>
        </BaseLayout>
    );
};
