import { MutableRefObject, useEffect, useRef } from "react";

export const useStickyBottom = (scrollContainerRef: MutableRefObject<HTMLElement | null>, loading = false) => {
    const wasAtBottomRef = useRef(true);
    const targetScrollRef = useRef<number | null>(null);

    // Scroll to the bottom initially with non-smooth scrolling
    useEffect(() => {
        const $e = scrollContainerRef.current;
        if (!$e) return;
        $e.scrollTo({ top: $e.scrollHeight, behavior: "instant" });
    }, [loading, scrollContainerRef]);

    // Set wasAtBottomRef to true if the user is at the bottom of the chat
    useEffect(() => {
        const $e = scrollContainerRef.current;
        if (!$e) return;

        const handleScroll = () => {
            wasAtBottomRef.current = $e.scrollTop + $e.clientHeight + 10 >= $e.scrollHeight;
        };

        $e.addEventListener("scroll", handleScroll);
        return () => $e.removeEventListener("scroll", handleScroll);
    }, [scrollContainerRef]);

    // Scroll to the bottom if the user was at the bottom before the chat updated
    useEffect(() => {
        const $e = scrollContainerRef.current;
        if (!$e) return;

        const observer = new MutationObserver(() => {
            // Was user not at the bottom before the chat updated? Do nothing
            if (!wasAtBottomRef.current) return;

            // Prevent scrolling if we're already scrolling to the same position
            // If we don't, scrolling will be jumpy as we are interrupting the smooth scroll
            if (targetScrollRef.current === $e.scrollHeight) return;
            targetScrollRef.current = $e.scrollHeight;

            $e.scrollTo({
                top: $e.scrollHeight,
                // Todo: reimplement this so it's not jumpy
                // behavior: "smooth",
            });
        });

        observer.observe($e, { childList: true, subtree: true, characterData: true });
        return () => observer.disconnect();
    }, [scrollContainerRef]);
};
