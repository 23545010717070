import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useMemoTopicsQuery = () => {
    return useQuery({
        queryKey: ["memoTopics"],
        queryFn: async () => {
            return await sdk.functional.memo.topic.getTopics(getApiConnection());
        },
    });
};
