import { Alert, Box, Button, Card, Heading, HStack, Icon, IconButton, Spacer, Spinner, Stack } from "@chakra-ui/react";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { faRotate } from "@fortawesome/free-solid-svg-icons/faRotate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import { StyledMarkdown } from "../../../components/misc/styledMarkdown/StyledMarkdown.tsx";
import { CopyToClipboard } from "../../../components/misc/copyToClipboard/CopyToClipboard.tsx";
import { SourcesPopover } from "../../../components/rag/sources/SourcePopover/SourcePopover.tsx";
import { aggregateSources } from "../../../lib/chat/aggregateSources.ts";
import { useRegenerateTopicMutation } from "../../../lib/mutations/useRegenerateTopicMutation.ts";
import { useMemoQuery } from "../../../lib/queries/useMemoQuery.ts";
import { getErrorDetails } from "../../../lib/util/getErrorMessage.ts";
import { getMemo } from "../../../sdk/functional/memo/cases/memos";
import { TopicInfo } from "../../../sdk/structures/TopicInfo.ts";

interface MemoProps {
    caseId: number;
    topic: TopicInfo;
    shouldRegenerate: boolean;
    onRegenerateComplete?: () => void;
}
export const MemoTopic: FC<MemoProps> = ({ caseId, topic, shouldRegenerate, onRegenerateComplete }) => {
    // Fetch single memo by topic
    const { data: memo, isLoading, isError, error } = useMemoQuery(caseId, topic.key);

    // Regenerate memo for this topic
    const {
        mutate: regenerateMemo,
        isLoading: isRegenerating,
        isError: isRegenerateError,
        error: regenerateError,
        isSuccess,
        reset,
    } = useRegenerateTopicMutation(caseId, topic.key);

    const sources = aggregateSources(memo?.text ?? "");

    useEffect(() => {
        if (shouldRegenerate && !isRegenerating && !isSuccess) regenerateMemo();
    }, [shouldRegenerate, isRegenerating, regenerateMemo, isSuccess]);

    useEffect(() => {
        if (!shouldRegenerate && isSuccess) reset();
    }, [shouldRegenerate, isSuccess, reset]);

    useEffect(() => {
        if (isSuccess && onRegenerateComplete) onRegenerateComplete();
    }, [isSuccess, onRegenerateComplete]);

    return (
        <Card variant={"flat"}>
            <Stack gap={4}>
                <HStack alignItems={"start"}>
                    {/* card title */}
                    <Heading as={"h2"} variant={"h2"}>
                        {topic.display_name}
                    </Heading>
                    <Spacer />
                    {/* refresh icon button */}
                    <IconButton
                        rounded={"xl"}
                        bg={"gray.300"}
                        aria-label={"refresh memo button"}
                        icon={<Icon as={FontAwesomeIcon} icon={faRotate} />}
                        onClick={() => regenerateMemo()}
                        isLoading={isRegenerating}
                    />
                </HStack>

                {/* card content */}
                {memo && <StyledMarkdown>{memo.text}</StyledMarkdown>}

                {isError && (
                    <Box>
                        {getErrorDetails(error).code === 404 ? (
                            "This topic hasn't been generated yet"
                        ) : (
                            <Alert status={"error"} variant={"subtle"}>
                                {getErrorDetails(error).message}
                            </Alert>
                        )}
                    </Box>
                )}

                {!isRegenerating && memo && (
                    <HStack flexWrap={"wrap"} justify={"space-between"}>
                        {sources.length > 0 && (
                            <SourcesPopover heading={"Sources"} chunkIds={sources} trigger={"hover"}>
                                {/* show all sources button */}
                                <Button
                                    bg={"gray.300"}
                                    _hover={{ bg: "gray.400" }}
                                    aria-label={"all source citation button"}
                                    leftIcon={<Icon as={FontAwesomeIcon} icon={faQuoteRight} />}
                                >
                                    Show all sources
                                </Button>
                            </SourcesPopover>
                        )}

                        {/* Spacer to push copy button to the right */}
                        <Spacer />

                        {/*copy to clipboard button*/}
                        <CopyToClipboard content={memo?.text} />
                    </HStack>
                )}

                {(isLoading || isRegenerating) && <Spinner />}
            </Stack>
        </Card>
    );
};
