import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useChatSessionsQuery = (caseId: number) => {
    return useQuery({
        queryKey: ["chatSessions", caseId],
        queryFn: async () => {
            return await sdk.functional.bot.cases.sessions.getSessions(getApiConnection(), caseId);
        },
    });
};
