import { HStack, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { faArrowDownWideShort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { SimilarCompaniesQuery } from "../../../sdk/structures/SimilarCompaniesQuery.ts";
import { FilterPopover } from "../../ui/filterPopover/FilterPopover.tsx";

const options = [
    // @todo: change values to specific python values.
    { value: "relevance", label: "Relevance" },
    { value: "recently_funded", label: "Recently funded" },
    { value: "total_funding", label: "Total funding" },
];

interface SortingFilterProps {
    sort: SimilarCompaniesQuery["sort"];
    onChange: (sort: SimilarCompaniesQuery["sort"]) => void;
}

export const SortingFilter: FC<SortingFilterProps> = ({ sort, onChange }) => {
    const { watch, setValue } = useForm<{ sort: SimilarCompaniesQuery["sort"] }>({
        defaultValues: { sort },
    });

    const activeOption = options.find((option) => option.value === sort);

    // Set form values when incoming values change
    useEffect(() => {
        setValue("sort", sort);
    }, [sort, setValue]);

    const cancel = () => {
        setValue("sort", sort);
        apply();
    };

    const apply = () => onChange(watch("sort"));

    return (
        <FilterPopover
            alignment={"bottom-end"}
            buttonLabel={
                <HStack gap={1}>
                    <Text>Sort by:</Text>
                    <Text fontWeight={"bold"} color={"purple.500"}>
                        {activeOption?.label ?? "Unknown"}
                    </Text>
                </HStack>
            }
            title={"Sort by"}
            onCancel={cancel}
            onApply={apply}
            icon={<FontAwesomeIcon icon={faArrowDownWideShort} />}
        >
            <RadioGroup
                colorScheme={"purple"}
                value={watch("sort")}
                onChange={(value) => {
                    setValue("sort", value as SimilarCompaniesQuery["sort"]);
                }}
            >
                <Stack>
                    {options.map((option) => (
                        <Radio key={option.value} value={option.value}>
                            {option.label}
                        </Radio>
                    ))}
                </Stack>
            </RadioGroup>
        </FilterPopover>
    );
};
