import { Alert, SkeletonText, Stack, Text } from "@chakra-ui/react";
import { FC } from "react";
import { useChunksQuery } from "../../../../lib/queries/useChunksQuery.ts";
import { getErrorDetails } from "../../../../lib/util/getErrorMessage.ts";
import { Source } from "../Source.tsx";

interface SourcesProps {
    chunkIds: string[];
}
export const Sources: FC<SourcesProps> = ({ chunkIds }) => {
    const { data: sources, isLoading, error } = useChunksQuery(chunkIds);
    const errorDetails = error ? getErrorDetails(error) : null;

    return (
        <Stack w={"full"} bg={"white"} gap={4} borderRadius={"2xl"}>
            {isLoading && <SkeletonText noOfLines={3} />}
            {!isLoading && sources && sources.length === 0 && <Text>No sources found</Text>}
            {!isLoading &&
                sources &&
                sources.length > 0 &&
                sources.map((source) => <Source key={source.assetId} source={source} />)}
            {errorDetails && <Alert status={"error"}>{errorDetails.message}</Alert>}
        </Stack>
    );
};
