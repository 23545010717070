/**
 * Tries to extract error messages from various types of errors
 * String -> returns as is,
 * Error object -> try parsing message, stack, and name
 * Object -> try parsing message, stack, and name
 * @param error
 */
export const getErrorDetails = (error: unknown) => {
    let code = 0;
    let message = "Unknown error";

    if (error instanceof Error && "isAxiosError" in error) {
        message = error.message ?? message;
        // @ts-expect-error status is not in Error but probably exists in AxiosError
        code = error.response?.status ?? code;
    } else if (error instanceof Error) {
        message = error.message ?? error.name;
        code = (error as any)?.code ?? code;
    } else if (typeof error === "string") {
        message = error;
        code = 0;
    } else if (typeof error === "object") {
        message = (error as any)?.message ?? (error as any)?.name;
        code = (error as any)?.code ?? code;
        code = (error as any)?.statusCode ?? code;
    }

    // Try further parsing the message as json
    try {
        const parsed = JSON.parse(message);
        if (parsed.message) message = parsed.message;
        if (parsed.code) code = parsed.code;
        if (parsed.statusCode) code = parsed.statusCode;
    } catch (e) {
        // Ignore
    }

    return { code, message };
};
