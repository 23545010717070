/**
 * @packageDocumentation
 * @module api.functional.asset.$case.assets.url
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { DownloadAssetDto } from "../../../../../structures/DownloadAssetDto";

/**
 * @controller AssetController.downloadAsset
 * @path GET /asset/case/:caseId/assets/:assetId/url
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function downloadAsset(
    connection: IConnection,
    caseId: number,
    assetId: number,
): Promise<downloadAsset.Output> {
    return PlainFetcher.fetch(connection, {
        ...downloadAsset.METADATA,
        template: downloadAsset.METADATA.path,
        path: downloadAsset.path(caseId, assetId),
    });
}
export namespace downloadAsset {
    export type Output = DownloadAssetDto;

    export const METADATA = {
        method: "GET",
        path: "/asset/case/:caseId/assets/:assetId/url",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number, assetId: number) =>
        `/asset/case/${encodeURIComponent(caseId ?? "null")}/assets/${encodeURIComponent(assetId ?? "null")}/url`;
}
