import { Avatar, Box, Heading, HStack, Icon, MenuDivider, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useAuth } from "react-oidc-context";

interface UserDropdownProps {}
export const UserDropdown: FC<UserDropdownProps> = ({}) => {
    const { signoutRedirect, user } = useAuth();

    // If you don't have a user you shouldn't be using this.
    if (!user) return null;

    return (
        <MenuList
            shadow={"dark"}
            border="none"
            maxW="350px"
            w="90dvw"
            px={2}
            paddingY={6}
            borderRadius="3xl"
            display="flex"
            flexDirection="column"
            gap={2}
            bg="black.500"
        >
            <HStack px={2} gap={3}>
                <Avatar size={"lg"} name={user.profile.name} src={user.profile.picture} />
                <Box>
                    <Heading color="white" size="sm">
                        {user.profile.name}
                    </Heading>
                    <Text color="white">{user.profile.email}</Text>
                </Box>
            </HStack>
            <MenuDivider mx={3} color="gray.800" />
            <MenuItem
                bg="black.500"
                gap={1}
                py={2}
                icon={<Icon color={"gray.500"} size={"xl"} as={FontAwesomeIcon} icon={faArrowRightFromBracket} />}
                onClick={() => signoutRedirect()}
            >
                <Text color="white" variant={"body2"}>
                    Log out
                </Text>
            </MenuItem>
        </MenuList>
    );
};
