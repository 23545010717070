import {
    Avatar,
    Heading,
    HStack,
    IconButton,
    SkeletonText,
    Spacer,
    Spinner,
    Stack,
    Tag,
    TagLabel,
    Text,
} from "@chakra-ui/react";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faLink, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useRegenerateCaseMutation } from "../../../lib/mutations/useRegenerateCaseMutation.ts";
import { useAttemptsQuery } from "../../../lib/queries/useAttemptsQuery.ts";
import { getCase } from "../../../sdk/functional/$case";

interface CaseHeaderProps {
    $case: getCase.Output;
}
export const CaseHeader: FC<CaseHeaderProps> = ({ $case }) => {
    const { mutate: regenerate } = useRegenerateCaseMutation($case.id);
    const { data: indicators } = useAttemptsQuery($case.id);

    return (
        <Stack gap={8}>
            {/*Icon*/}
            <Avatar name={$case.name} size={"xl"} boxSize={20} borderRadius={"3xl"} src={$case.logo_url ?? undefined} />

            {/*Title + description*/}
            <Stack gap={4}>
                <HStack>
                    <Heading variant={"h1"} as={"h1"}>
                        {$case.name}
                    </Heading>
                    <Spacer />
                    {indicators?.description && (
                        <Tag variant={"loader"}>
                            <Spinner color={"purple.500"} speed={"1s"} size="xs" />
                            <TagLabel>Generating case</TagLabel>
                        </Tag>
                    )}
                </HStack>

                {$case.description && <Text lineHeight={"taller"}>{$case.description}</Text>}
                {!$case.description && <SkeletonText noOfLines={4} skeletonHeight={3} spacing={3} w={"full"} my={4} />}
            </Stack>

            {/*Buttons*/}
            <HStack gap={4}>
                {$case.website && (
                    <Link to={$case.website} target={"_blank"} rel={"noreferrer"}>
                        <IconButton
                            borderRadius={"lg"}
                            aria-label={"Website"}
                            size={"sm"}
                            colorScheme={"gray"}
                            bg={"gray.300"}
                            icon={<FontAwesomeIcon icon={faLink} />}
                        />
                    </Link>
                )}

                {$case.linkedin_url && (
                    <Link to={$case.linkedin_url} target={"_blank"} rel={"noreferrer"}>
                        <IconButton
                            borderRadius={"lg"}
                            aria-label={"Linkedin"}
                            colorScheme={"linkedinBlue"}
                            size={"sm"}
                            icon={<FontAwesomeIcon icon={faLinkedinIn} />}
                        />
                    </Link>
                )}

                <Spacer />

                <IconButton
                    aria-label={"Regenerate"}
                    size={"sm"}
                    colorScheme={"gray"}
                    bg={"gray.300"}
                    borderRadius={"lg"}
                    icon={<FontAwesomeIcon icon={faWandMagicSparkles} />}
                    onClick={() => regenerate()}
                />
            </HStack>
            {/*</Center>*/}
            {/*</Card>*/}
        </Stack>
    );
};
