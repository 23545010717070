/**
 * @packageDocumentation
 * @module api.functional.bot.cases.sessions
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Format } from "typia/lib/tags/Format";

/**
 * @controller BotController.getSessions
 * @path GET /bot/cases/:caseId/sessions
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getSessions(connection: IConnection, caseId: number): Promise<getSessions.Output> {
    return PlainFetcher.fetch(connection, {
        ...getSessions.METADATA,
        template: getSessions.METADATA.path,
        path: getSessions.path(caseId),
    });
}
export namespace getSessions {
    export type Output = {
        id: number;
        user_id: number;
        case_id: number;
        created_at: string & Format<"date-time">;
    }[];

    export const METADATA = {
        method: "GET",
        path: "/bot/cases/:caseId/sessions",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number) => `/bot/cases/${encodeURIComponent(caseId ?? "null")}/sessions`;
}
