import { Button, Center, Heading, Icon, Text } from "@chakra-ui/react";
import { faArrowUpFromBracket, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { mimetypes } from "../../../lib/util/mimetypes.ts";

interface UploadBoxProps extends Partial<DropzoneOptions> {
    label?: string;
    currentFile?: File | null;
    isError?: boolean;
    onClear?: () => void;
}
export const UploadBox: FC<UploadBoxProps> = ({ currentFile, isError, label, onClear, ...options }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        maxFiles: 1,
        accept: {
            [mimetypes.pdf]: [".pdf"],
            [mimetypes.doc]: [".doc"],
            [mimetypes.docx]: [".docx"],
            [mimetypes.ppt]: [".ppt"],
            [mimetypes.pptx]: [".pptx"],
            [mimetypes.txt]: [".txt"],
            [mimetypes.csv]: [".csv"],
            [mimetypes.xls]: [".xls"],
            [mimetypes.xlsx]: [".xlsx"],
        }, // Default to PDF files
        maxSize: 50 * 1024 * 1024, // Default to 50MB
        ...options,
    });

    return (
        <Center
            {...getRootProps()}
            bg={isDragActive ? "white" : "gray.100"}
            h={60}
            flexDir={"column"}
            gap={4}
            textColor={"gray.400"}
            borderRadius={"xl"}
            borderColor={"gray.300"}
            borderWidth={"2px"}
            borderStyle={"dashed"}
            cursor={"pointer"}
            _hover={{ bg: "gray.50" }}
            outline={isError ? "2px solid var(--chakra-colors-red-500)" : "none"}
            outlineOffset={-2}
        >
            {/*Hidden input, needed for opening file dialog*/}
            <input type={"file"} {...getInputProps()} />

            {!isDragActive && !currentFile && (
                <>
                    <Icon as={FontAwesomeIcon} icon={faArrowUpFromBracket} boxSize={6} color={"gray.500"} />
                    {label && <Text>{label}</Text>}
                    {!label && (
                        <Text variant={"body2"} color={"gray.500"} align={"center"}>
                            Drag your file(s) here or click to select.
                        </Text>
                    )}
                </>
            )}
            {!isDragActive && currentFile && (
                <>
                    <Icon as={FontAwesomeIcon} icon={faCircleCheck} boxSize={7} />
                    <Text>{currentFile.name}</Text>
                    {onClear && (
                        <Button
                            variant={"ghost"}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClear();
                            }}
                        >
                            Clear file
                        </Button>
                    )}
                </>
            )}
            {isDragActive && <Heading>Drop to upload</Heading>}
        </Center>
    );
};
