import { Box, Container, HStack, Icon, IconButton, Stack } from "@chakra-ui/react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { SideNav } from "../../navigation/sideNav/SideNav.tsx";

interface SideNavLayoutProps extends PropsWithChildren {
    hasContainer?: boolean;
    hasBackButton?: boolean;
}

export const SideNavLayout: FC<SideNavLayoutProps> = ({ children, hasContainer = true, hasBackButton = false }) => {
    const navigate = useNavigate();

    return (
        <HStack alignItems="stretch" minH={"100dvh"} background={"white"} gap="0">
            <SideNav />

            {/* To position the back button */}
            <Stack flexGrow={1} position={"relative"}>
                {/* Back button */}
                {/* A bit hacky because we are dealing with sidenav being hacky */}
                {hasBackButton && (
                    <IconButton
                        variant={"ghost"}
                        color={"gray.800"}
                        position={"absolute"}
                        top={"40px"} // 40px is the width of the sidenav
                        left={"40px"}
                        icon={<Icon fontSize={"xl"} as={FontAwesomeIcon} icon={faArrowLeft} />}
                        aria-label={"back button"}
                        onClick={() => navigate(-1)}
                    />
                )}

                {hasContainer && (
                    <Container py="8" px="24" flexGrow={1} pos={"relative"} zIndex={10} as={Stack}>
                        {children}
                    </Container>
                )}

                {!hasContainer && children}
            </Stack>
        </HStack>
    );
};
