/**
 * @packageDocumentation
 * @module api.functional.bot.sessions.messages
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Format } from "typia/lib/tags/Format";

import type { SendMessageDto } from "../../../../structures/SendMessageDto";

/**
 * @controller BotController.getMessages
 * @path GET /bot/sessions/:sessionId/messages
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getMessages(connection: IConnection, sessionId: number): Promise<getMessages.Output> {
    return PlainFetcher.fetch(connection, {
        ...getMessages.METADATA,
        template: getMessages.METADATA.path,
        path: getMessages.path(sessionId),
    });
}
export namespace getMessages {
    export type Output = {
        id: number;
        content: string;
        is_user: boolean;
        created_at: string & Format<"date-time">;
        session_id: number;
        user_id: number;
        case_id: number;
    }[];

    export const METADATA = {
        method: "GET",
        path: "/bot/sessions/:sessionId/messages",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (sessionId: number) => `/bot/sessions/${encodeURIComponent(sessionId ?? "null")}/messages`;
}

/**
 * @controller BotController.sendMessage
 * @path POST /bot/sessions/:sessionId/messages
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function sendMessage(connection: IConnection, sessionId: number, body: sendMessage.Input): Promise<void> {
    return PlainFetcher.fetch(
        {
            ...connection,
            headers: {
                ...connection.headers,
                "Content-Type": "application/json",
            },
        },
        {
            ...sendMessage.METADATA,
            template: sendMessage.METADATA.path,
            path: sendMessage.path(sessionId),
        },
        body,
    );
}
export namespace sendMessage {
    export type Input = SendMessageDto;

    export const METADATA = {
        method: "POST",
        path: "/bot/sessions/:sessionId/messages",
        request: {
            type: "application/json",
            encrypted: false,
        },
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (sessionId: number) => `/bot/sessions/${encodeURIComponent(sessionId ?? "null")}/messages`;
}
