/**
 * @packageDocumentation
 * @module api.functional.$case.attempts
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Format } from "typia/lib/tags/Format";

/**
 * @controller CaseController.getAttempts
 * @path GET /case/:id/attempts
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getAttempts(connection: IConnection, id: number): Promise<getAttempts.Output> {
    return PlainFetcher.fetch(connection, {
        ...getAttempts.METADATA,
        template: getAttempts.METADATA.path,
        path: getAttempts.path(id),
    });
}
export namespace getAttempts {
    export type Output = {
        id: number;
        case_id: number;
        data_type: string;
        success: null | boolean;
        started_at: string & Format<"date-time">;
        completed_at: null | (string & Format<"date-time">);
        error: null | string;
    }[];

    export const METADATA = {
        method: "GET",
        path: "/case/:id/attempts",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (id: number) => `/case/${encodeURIComponent(id ?? "null")}/attempts`;
}
